import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import clsx from 'clsx'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'

interface WeekPickerProps {
    onDateSelected: (date: Date | undefined) => void,
    date: Date | undefined
}

const useStyles = makeStyles((theme) => ({
    dayWrapper: {
        position: "relative",
    },
    day: {
        width: 36,
        height: 36,
        fontSize: theme.typography.caption.fontSize,
        margin: "0 2px",
        color: "inherit",
    },
    customDayHighlight: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: "2px",
        right: "2px",
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: "40%",
    },
    nonCurrentMonthDay: {
        color: theme.palette.text.disabled,
    },
    highlightNonCurrentMonthDay: {
        color: "#676767",
    },
    highlight: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    firstHighlight: {
        extend: "highlight",
        borderTopLeftRadius: "40%",
        borderBottomLeftRadius: "40%",
    },
    endHighlight: {
        extend: "highlight",
        borderTopRightRadius: "40%",
        borderBottomRightRadius: "40%",
    },
}));


const WeekPicker = (props: WeekPickerProps) => {
    const classes = useStyles()
    const renderDay = (dayParam: MaterialUiPickersDate, selectedDate: MaterialUiPickersDate, dayInCurrentMonth: boolean/* , dayComponent: JSX.Element */): JSX.Element => {

        if (dayParam === null)
            throw new Error('day must not be null')

        const day = moment(dayParam.valueOf())

        const start = !!selectedDate ? moment(selectedDate.valueOf()).startOf("week") : moment()
        const end = !!selectedDate ? moment(selectedDate.valueOf()).endOf("week") : moment()

        const dayIsBetween = day.valueOf() >= (start?.valueOf() || 0) && day.valueOf() <= (end?.valueOf() || 0)
        const isFirstDay = day.diff(start, "day") === 0
        const isLastDay = day.diff(end, "day") === 0

        const wrapperClassName = clsx({
            [classes.highlight]: dayIsBetween,
            [classes.firstHighlight]: isFirstDay,
            [classes.endHighlight]: isLastDay,
        });

        const dayClassName = clsx(classes.day, {
            [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
            [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
        });

        return (
            <div className={wrapperClassName}>
                <IconButton className={dayClassName}>
                    <span> {day.format("D")} </span>
                </IconButton>
            </div>
        );
    }

    const { date, onDateSelected } = props

    // this should move up the tree if pickers would be re-used somewhere else
    // for now I'll contain it here though
    return  <DatePicker
            onChange={(newVal) => onDateSelected(newVal?.toDate())}
            value={date}
            autoOk={true}
            format="MMM YYYY [KW] w"
            renderDay={renderDay}
        />
}

export default WeekPicker