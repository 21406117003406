import React from "react"
import Button, { ButtonProps } from "@material-ui/core/Button"

const ToolbarButton = ({ style, ...props }: ButtonProps | any) => {
	return <Button {...props} style={{
		...style,
		textTransform: style?.textTransform || "capitalize"
	}}/>
}

export default ToolbarButton