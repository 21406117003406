import React, { createContext, useState, useCallback, useEffect, useRef } from "react"
import { Reservation, ReservationApi, ReservationInfo, sameResFilter } from "../api"
import { useShowMessage } from "./MessageContext"
import { useHandleReservationError, useReWake } from "../hooks"
import { TimeLine } from "../tools/TimeLine"
import { useSocket } from "./SocketContext"
import { useAuth } from "./AuthContext"
import { useLoading } from './LoadingContext'
import useReservationsRules from '../hooks/useReservationRules'

interface ReservationContextValue {
    cancelReservation: (reservation: Reservation) => void,
    saveReservation: (reservation: Reservation) => void,
    multiReservation: (resInfo: ReservationInfo[], description: string, cancel: boolean) => Promise<boolean | void>,
    reservations: Reservation[],
    fetch: (from: Date, to: Date) => void
}

interface ReservationContextProviderOptions {
    children: React.ReactNode
}

const ReservationContext = createContext<ReservationContextValue>({
    cancelReservation: () => { },
    saveReservation: () => { },
    multiReservation: () => Promise.reject(),
    reservations: [],
    fetch: () => { }
})

export const ReservationContextProvider = ({ children }: ReservationContextProviderOptions) => {
    const showMessage = useShowMessage()

    const { indicateLoading } = useLoading()
    const { user } = useAuth()

    const { calcRulePeriodStart, calcRulePeriodEnd } = useReservationsRules()

    const [reservations, setReservations] = useState<Reservation[]>([])

    const socket = useSocket()

    const merge = useCallback((resUpdate: Reservation[]) => {
        setReservations(oldRes => oldRes.filter(or => !resUpdate.find(sameResFilter(or))).concat(resUpdate))
    }, [])

    const remove = useCallback((resUpdate: Reservation[]) => {
        setReservations(oldRes => oldRes.filter(or => !resUpdate.find(sameResFilter(or))))
    }, [])

    // Websocket updates
    const handleUpdate = useCallback((update: any) => {
        const resUpdate = update.map(ReservationApi.parseApiReservationResponse)
        merge(resUpdate)
    }, [merge])

    const handleRemove = useCallback((update: any) => {
        const resUpdate = update.map(ReservationApi.parseApiReservationResponse)
        remove(resUpdate)
    }, [remove])

    useEffect(() => {
        socket?.on("reservationsCreated", handleUpdate)
        socket?.on("reservationsCancelled", handleRemove)
        return () => {
            socket?.off("reservationsCreated", handleUpdate)
            socket?.off("reservationsCancelled", handleRemove)
        }
    }, [socket, handleUpdate, handleRemove])


    // Manipulation
    const handleReservationError = useHandleReservationError()

    const cancelReservation = useCallback((reservation: Reservation) => {
        if (!user) return

        ReservationApi.cancelReservation(user, reservation)
            .then(() => {
                showMessage({
                    message: "Reservierung storniert",
                    severity: "success",
                    duration: 3000
                })
                remove([reservation])
            })
            .catch(handleReservationError)
            .then(indicateLoading())
    }, [user, showMessage, handleReservationError, remove, indicateLoading])

    const saveReservation = useCallback((reservation: Reservation) => {
        if (!user) return
        ReservationApi.saveReservation(user, reservation)
            .then((savedRes) => {
                showMessage({
                    message: "Reserviert!", severity: "success",
                    duration: 3000
                })
                merge([savedRes])
            })
            .catch(handleReservationError)
            .then(indicateLoading())
    }, [user, showMessage, handleReservationError, merge, indicateLoading])

    const multiReservation = useCallback((resInfos: ReservationInfo[], description: string, cancel: boolean): Promise<boolean | void> => {
        if (!user) return Promise.reject()
        return ReservationApi.multiReservation(user, resInfos, description, cancel)
            .then((res) => {
                showMessage({
                    message: "Reserviert!", severity: "success",
                    duration: 3000
                })

                remove(res.cancelled)
                merge(res.reserved)
                return true
            })
            .catch(handleReservationError)
            .finally(indicateLoading())
    }, [user, handleReservationError, indicateLoading, merge, remove, showMessage])

    const coveredPeriod = useRef<TimeLine>(TimeLine.empty())
    const fetch = useCallback((from: Date, to: Date) => {
        const fetchStart = calcRulePeriodStart(from)
        const fetchEnd = calcRulePeriodEnd(to)
        if (coveredPeriod.current.covers(fetchStart, fetchEnd))
            return

        coveredPeriod.current = coveredPeriod.current.concat(fetchStart, fetchEnd)

        ReservationApi.getReservations(fetchStart, fetchEnd)
            .then(merge)
            .catch(() => {
                coveredPeriod.current = coveredPeriod.current.remove(fetchStart, fetchEnd)
            })
            .finally(indicateLoading())
    }, [calcRulePeriodStart, calcRulePeriodEnd, merge, indicateLoading])

    const handleRewake = useCallback(() => {
        coveredPeriod.current = TimeLine.empty()

        //clearing all old reservations as fetch won't delete, just adds (maybe that should be changed at some point)
        setReservations([])

        //loading full month ... probably not most efficient way of doing things
        const today = new Date()
        fetch(new Date(today.getFullYear(), today.getMonth(), 1), new Date(today.getFullYear(), today.getMonth() + 1, 1))
    }, [fetch, setReservations])

    // useReWake(handleRewake)

    return <ReservationContext.Provider
        value={{
            saveReservation,
            cancelReservation,
            multiReservation,
            reservations,
            fetch
        }}
        children={children}
    />
}

export const useReservations = () => React.useContext(ReservationContext)
