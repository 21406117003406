import { User } from "./User"
import { AuthError } from "./AuthError"

export type Member = {
	id: string,
	firstName?: string,
	lastName: string,
	playedWithMe: number
}

export class MembersApi {

	static async getMembers(user: User): Promise<Member[]> {

		const url = new URL("./members", process.env.REACT_APP_API_BASE_URL)

		const response = await fetch(url.toString(), {
			headers: new Headers({
				"Authorization": `Bearer ${user.token}`
			})
		})
		switch (response.status) {
			case 200:
				break
			case 401:
				throw new AuthError()
			default:
				throw new Error(response.status + " " + response.statusText)
		}

		return await response.json()
	}
}