import React, { createContext, useRef, useState, useCallback } from "react"
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ReservationInfo } from "../../api";

export interface MultiResDialogOptions {
	selected: ReservationInfo[],
	cancel: boolean
}

export interface MultiResDialogResult {
	description: string
}

const MultiResDialogContext = createContext<(options: MultiResDialogOptions) => Promise<MultiResDialogResult>>(() => Promise.reject())

export const MultiResDialogProvider = ({ children }: { children: React.ReactNode }) => {
	const [options, setOptions] = useState<MultiResDialogOptions>()

	const [description, setDescription] = useState("")

	const awaitingPromiseRef = useRef<{
		resolve: (value: MultiResDialogResult | PromiseLike<MultiResDialogResult>) => void;
		reject: (reason?: any) => void;
	}>()

	const showMultiResDialog = useCallback((options: MultiResDialogOptions): Promise<MultiResDialogResult> => {
		setOptions(options)
		setDescription("")
		return new Promise<MultiResDialogResult>((resolve, reject) => {
			awaitingPromiseRef.current = { resolve, reject }
		})
	}, [])

	const handleClose = useCallback(
		() => {
			awaitingPromiseRef.current?.reject()
			setOptions(undefined)
		},
		[awaitingPromiseRef, setOptions],
	)

	const hanldeOk = useCallback(
		() => {
			awaitingPromiseRef.current?.resolve({ description })
			setOptions(undefined)
		},
		[awaitingPromiseRef, description],
	)

	return (<React.Fragment>
		<MultiResDialogContext.Provider
			value={showMultiResDialog}
			children={children}
		/>
		{!options ? null :
			<Dialog open={!!options} onClose={handleClose} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">Multi reservierung</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						label="Beschreibung"
						value={description}
						onChange={(e) => setDescription(e.target.value)}
						fullWidth
						required
					/>
					{/* <List>
						{options.selected.sort((a, b) => a.hour.getTime() - b.hour.getTime())
							.map(sel => (<ListItem key={"" + sel.courtId + sel.hour.getTime()}>
								<ListItemIcon>
									{!!sel.reservation ? <SwapHorizIcon /> : <AddIcon />}
								</ListItemIcon>
								<ListItemText primary={`${sel.courtId} - ${moment(sel.hour).format("DD.MM.YY HH:mm")}`} />
							</ListItem>))}
					</List> */}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Abbrechen
					</Button>
					<Button onClick={hanldeOk} color="primary" disabled={!description}>
						{!options.cancel ? "Reservieren" : "Stornieren"}
					</Button>
				</DialogActions>
			</Dialog>
		}

	</React.Fragment>)

}

export const useShowMultiResDialog = () => React.useContext(MultiResDialogContext)
