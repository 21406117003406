import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Member } from '../../../../api';
import formatName from '../../tools/formatName';

interface MemberSelectionProps {
  className?: string
  allMembers: Member[],
  selectedMember: Member | null,
  onSelectionChanged: (member: Member|null) => void
}

const MemberSelection = ({ className, allMembers, selectedMember, onSelectionChanged }: MemberSelectionProps) => {
  return <Autocomplete
    className={className}
    options={allMembers}
    openOnFocus={true}
    getOptionLabel={(option) => formatName(option)}
    renderInput={(params) => <TextField {...params} placeholder="Spieler suchen" />}
    value={selectedMember}
    onChange={(e: any, member: Member | null) => onSelectionChanged(member)}
  />
}

export default MemberSelection