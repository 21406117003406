import {
	Court,
	Reservation,
	ReservationInfo
} from "../api"
import { useMemo } from "react"
import useReservationRules from "./useReservationRules" 
import { getHoursInRange } from "../tools/SelectionHelper"
interface ReservationInfoProp {
	allReservations: Reservation[]
	showFromMS: number,
	showToMS: number,
	courts: Court[]
}

const useReservationsInfo = (options: ReservationInfoProp): ReservationInfo[] => {
	const { allReservations, showFromMS, showToMS, courts } = options

	const { bulkCheckCanReserve, bulkCheckCanCancel } = useReservationRules()

	const reservationInfo = useMemo<ReservationInfo[]>(() => {
		const info: ReservationInfo[] = []

		const from = new Date(showFromMS),
			to = new Date(showToMS)

		getHoursInRange(from, to).forEach(day => {
			courts.forEach(court => {
				const reservation = allReservations.find(res => res.courtId === court.id && res.hour.getTime() === day.getTime())

				if (reservation) {
					info.push({
						courtId: court.id,
						hour: new Date(day),
						reservation,
						reservationError: "Reserved",
						cancellationError: undefined
					})
				} else {
					info.push({
						courtId: court.id,
						hour: new Date(day),
						reservationError: undefined,
						cancellationError: "No Reservation"
					})
				}

			})
		})
		bulkCheckCanReserve({
			allReservations,
			reservationsInfo: info.filter(ri => !ri.reservation)
		})
		bulkCheckCanCancel({
			allReservations,
			reservationsInfo: info.filter(ri => !!ri.reservation)
		})
		return info
	}, [allReservations, courts, showFromMS, showToMS, bulkCheckCanReserve, bulkCheckCanCancel])

	return reservationInfo
}

export default useReservationsInfo