import React, { useEffect, useState } from "react"
import Box from "@material-ui/core/Box"
import { createStyles, makeStyles } from "@material-ui/core/styles"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import IconButton from "@material-ui/core/IconButton"
import Button from "@material-ui/core/Button"
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import ChevronRight from "@material-ui/icons/ChevronRight"
import moment from "moment"

import { DateTools } from "../../../../tools"
import { ReservationViewVariantProps } from "../ReservationViewVariantProps"
import { useReWake, useReservationsInfo } from "../../../../hooks"
import Day from './Day'
import WeekPicker from "../../../WeekPicker"
import { useResInfoSelection } from "../../../../context"

const useStyles = makeStyles((theme) => createStyles({
	reservationWrapper: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		flex: 1,
		maxWidth: "100%",
		padding: theme.spacing(1)
	},
	calendarWeek: {
		display: "flex",
		justifyContent: "center",
		marginBottom: theme.spacing(1)
	},
	weeksWithNav: {
		flex: 1,
		paddingTop: theme.spacing(3),
		maxHeight: "40em",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
	}
}
))

const WeekView = (props: ReservationViewVariantProps) => {
	const { courts, reservations, fetch, onInfoClick, user, multiResClick, multiCancelClick, clearSelClick, verbose
	} = props

	const [referenceDate, setReferenceDate] = useState<Date | undefined>(new Date())
	const { multiSelectEnabled, toggleMultiSelect, selected } = useResInfoSelection()

	const showFrom = moment(referenceDate).startOf("weeks").toDate()
	const showFromMS = showFrom.getTime()

	const showTo = moment(referenceDate).endOf("weeks").toDate()
	const showToMS = showTo.getTime()

	const reservationsInfo = useReservationsInfo({
		allReservations: reservations,
		courts,
		showFromMS,
		showToMS
	})

	useEffect(() => {
		fetch(new Date(showFromMS), new Date(showToMS))
	}, [fetch, showFromMS, showToMS])


	// useReWake(() => setReferenceDate(new Date()))

	const nextWeek = () => setReferenceDate(oldRef => !oldRef ? new Date() : new Date(oldRef.setDate(oldRef.getDate() + 7)))
	const lastWeek = () => setReferenceDate(oldRef => !oldRef ? new Date() : new Date(oldRef.setDate(oldRef.getDate() - 7)))

	const classes = useStyles()

	return (
		<Box className={classes.reservationWrapper}>
			<Box>
				<WeekPicker onDateSelected={setReferenceDate}
					date={referenceDate} />
				<Button color="primary" onClick={() => setReferenceDate(new Date())}>Heute anzeigen</Button>
			</Box>
			<Box className={classes.weeksWithNav}>
				<IconButton aria-label="Letzte Woche" onClick={lastWeek}>
					<ChevronLeft />
				</IconButton>
				<Box className={classes.calendarWeek}>{
					DateTools.getDaysBetween(showFrom, showTo).map((day, index) =>
						<Day key={day.toISOString()}
							day={day}
							courts={courts}
							onInfoClick={onInfoClick}
							showHours={index === 0}
							reservationsInfo={reservationsInfo}
							user={user}
							verbose={verbose}
						/>)
				}</Box>
				<IconButton aria-label="Nächste Woche" onClick={nextWeek}>
					<ChevronRight />
				</IconButton>
			</Box>
			{user?.role?.multiReservation && <Box>
				<FormControlLabel
					control={<Checkbox color="primary" checked={multiSelectEnabled} onChange={toggleMultiSelect} />}
					label="Multi"
				/>
				<Button variant="contained" color="primary" disabled={selected.length <= 0} onClick={multiResClick}>{selected.length} reservieren</Button>
				<Button variant="contained" color="primary" disabled={selected.length <= 0} onClick={multiCancelClick}>Stornieren</Button>
				<Button variant="contained" color="secondary" disabled={selected.length <= 0} onClick={clearSelClick}>Auswahl löschen</Button>
			</Box>}
		</Box>
	)
}

export default WeekView