import { parseJwt } from "./TokenParser"
import { Role } from "./Role"

export class User {

	readonly token: string
	readonly role: Role
	readonly roleId: string
	readonly id: string
	readonly firstName: string | undefined
	readonly lastName: string | undefined

	constructor(token: string) {
		this.token = token

		const decodedToken = parseJwt(token)

		this.role = decodedToken.role
		this.roleId = this.role.id
		this.id = decodedToken.sub
		this.firstName = decodedToken.firstName
		this.lastName = decodedToken.lastName
	}
}