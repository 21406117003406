import React from "react"
import moment from "moment"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"

type DayProps = {
	date: Date,
	isSelected: boolean,
	onSelect: () => void
}

const useStyles = makeStyles((theme: Theme) => createStyles({
	wrapper: {
		display: "flex",
		flex: "1",
		flexDirection:"column",
		marginRight: theme.spacing(0.5),
		"&:last-child": {
			marginRight: 0
		},
		alignItems: "stretch"
	},
	dayButton: {
		display: "flex",
		flex: "1",
		flexDirection:"column",
		minWidth: 30,
		padding: theme.spacing(1),
		paddingTop: theme.spacing(0.75),
		paddingBottom: theme.spacing(0.75),
		lineHeight: 1
	},
	text: {
		lineHeight: 1
	},
	monthBox: {
		height: 20,
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	},
	monthText: {
		textTransform: "none"
	}
}
))

const Day = (props: DayProps) => {
	const classes = useStyles(props)

	const { date, onSelect, isSelected } = props
	const displayDate = moment(date)
	const isToday = displayDate.isSame(new Date(), "day")

	return <Box className={classes.wrapper} >
		<Box className={classes.monthBox}>
			{(date.getDate() === 1 || isSelected) && <Typography className={[classes.text, classes.monthText].join(" ")}
				variant="overline">{displayDate.format("MMM")}</Typography>}
		</Box>
		<Button className={classes.dayButton} onClick={onSelect}
			variant={isSelected || isToday ? "contained" : "text"}
			color={isSelected ? (isToday ? "secondary" : "primary") : undefined}>
			<Box>
				<Typography className={classes.text}
					variant="body1">{displayDate.format("dd")}</Typography>
				<Typography className={classes.text}
					variant="caption">{displayDate.format("DD")}</Typography>
			</Box>
		</Button></Box>
}

export default React.memo(Day)