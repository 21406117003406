export type Court = {
	id: number,
	name: string
}

class CourtApi {
	static getCourts(): Promise<Court[]> {
		return fetch(new URL("./courts", process.env.REACT_APP_API_BASE_URL).toString()).then(res => res.json())
	}
}

export { CourtApi }