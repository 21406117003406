import React from 'react'
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import { makeStyles, createStyles } from "@material-ui/core/styles"
import moment from 'moment'
import CourtsView from '../../../CourtsView'
import { Court, ReservationInfo, User } from '../../../../api'

const useStyles = makeStyles((theme) => createStyles({
    dayWrapper: {
        display: "flex",
        flexDirection: "column",
        paddingRight: theme.spacing(1)
    }
}))

interface DayProps {
    day: Date,
    courts: Court[],
    user?: User
    reservationsInfo: ReservationInfo[],
    onInfoClick: (info: ReservationInfo) => void,
    showHours: boolean,
    verbose?: boolean
}

const Day = ({ day, courts, reservationsInfo, onInfoClick, showHours, user, verbose }: DayProps) => {
    const classes = useStyles()
    return <Box className={classes.dayWrapper}
        key={day.toISOString()}>
        <Typography
            variant="body2"
            align="center">{moment(day).format("dd DD.MM")}</Typography>
        <CourtsView
            forDate={day}
            courts={courts}
            reservationInfo={reservationsInfo}
            onInfoClick={onInfoClick}
            showHours={showHours}
            user={user}
            verbose={verbose} />
    </Box>
}

export default Day