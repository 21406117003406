import React, { createContext, useState, useEffect } from "react"
import { Member, MembersApi } from "../api"
import { useAuth } from "./AuthContext"
import { useShowMessage } from "./MessageContext"
import { useLoading } from "./LoadingContext"

const MembersContext = createContext<Member[]>([])

interface MembersContextProviderOptions {
    children: React.ReactNode
}

export const MembersContextProvider = ({ children }: MembersContextProviderOptions) => {
    const [members, setMembers] = useState<Member[]>([])

    const { user } = useAuth()
    const showMessage = useShowMessage()
    const { indicateLoading } = useLoading()

    useEffect(() => {
        if (!user) return setMembers([])
        MembersApi.getMembers(user)
            .then(setMembers)
            .catch(() => showMessage({
                message: "Fehler beim laden",
                severity: "error"
            }))
            .finally(indicateLoading())
    }, [user, showMessage, indicateLoading])

    return <MembersContext.Provider value={members} children={children} />
}

export const useMembers = () => React.useContext(MembersContext)
