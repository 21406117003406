import React, { createContext, useState } from "react"
import { useHistory } from "react-router"

export type ClubHouseContextValue = {
    inClubMode: boolean
    authorize: (username: string, password: string) => Promise<void>
}

const ClubHouseContext = createContext<ClubHouseContextValue>({
    inClubMode: false,
    authorize: () => Promise.resolve()
})

const CLUB_HOUSE_STORAGE_KEY = "CLUB_HOUSE"

export const ClubHouseContextProvider = ({ children }: { children: React.ReactNode }) => {
    const history = useHistory()
    const [clubHouseToken, setClubHouseToken] = useState<string | null>(localStorage.getItem(CLUB_HOUSE_STORAGE_KEY))

    const enableClubhouse = () => {
        const theToken = "aToken"
        localStorage.setItem(CLUB_HOUSE_STORAGE_KEY, theToken)
        setClubHouseToken(theToken)
    }

    // this is about as unsafe as it gets ...
    const authorize = (username: string, password: string): Promise<void> => {
        if (username !== "svl" || password !== "svl123!")
            return Promise.reject("Falscher Username oder passwort")
        enableClubhouse()
        history.push("/")
        return Promise.resolve()
    }

    return (<React.Fragment>
        <ClubHouseContext.Provider
            value={{ authorize, inClubMode: !!clubHouseToken }}
            children={children}
        />
    </React.Fragment>)

}

export const useClubHouse = () => React.useContext(ClubHouseContext)
