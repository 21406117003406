import Box from "@material-ui/core/Box"
import { Route, Switch, Redirect } from "react-router-dom"
import { LoginForm } from "../Login"
import ReservationView from "../ReservationView"
import {
	createStyles,
	makeStyles
} from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"

import { useAuth, LoadingContextProvider, ReservationContextProvider, MembersContextProvider, CourtsContextProvider, ResInfoSelectionProvider, useShowMessage, useClubHouse } from "../../context"

import { LoadingBarIndicator } from "../LoadingIndicator"
import { ReservationDialogProvider } from "../ReservationDialog"
import { MultiResDialogProvider } from "../MultiReservationDialog"
import { UserList, UserRoute } from "../UserManagement"
import { AuthenticatedRoute } from "./AuthenticatedRoute"
import moment from 'moment'
import "moment/locale/de-at";
import MomentUtils from "@date-io/moment";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { SingleDayViewActivation } from "../SingleDayViewActivation"
import Toolbar from './Toolbar'
import IdleTimer from 'react-idle-timer'
import { useEffect, useState } from "react"
import { DateTools } from "../../tools"

const useStyles = makeStyles((theme) => createStyles({
	appWrapper: {
		display: "flex",
		height: "100vh",
		width: "100vw",
		maxWidth: "100%",
		flexDirection: "column"
	},
	loadingWrapper: {
		flexGrow: 1,
		display: "flex",
		flexDirection: "column"
	},
	contentWrapper: {
		flexGrow: 1,
		display: "flex",
		flexDirection: "column",
		margin: theme.spacing(1)
	},
	title: {
		textDecoration: "none",
		color: theme.palette.primary.contrastText,
		flexGrow: 1
	}
}
))

const TIMEOUT = 3 * 60 * 1000 // 3min

const MainNav = () => {
	const { login, user, logout } = useAuth()
	const [today, setToday] = useState(new Date())
	const { inClubMode } = useClubHouse()
	let _idleTimerRef = null

	const showMessage = useShowMessage()
	const handleIdle = () => {
		if (user && inClubMode) {
			const name = [user.firstName, user.lastName].map(a => a || "").filter(a => a.length > 0).join(" ")
			logout()
			showMessage(
				{
					message: `${name} wurde nach ${Math.round(TIMEOUT / 60000)} Minuten Inaktivität automatisch abgemeldet!`
					, severity: "warning"
				})
		}
	}

	useEffect(() => {
		const timer = setInterval(() => {
			setToday((old) => {
				if (DateTools.getDateOnly(old).getTime() !== DateTools.getDateOnly(new Date()).getTime())
					return new Date()
				return old
			})
		}, 60 * 1000) // check every minute

		return () => clearInterval(timer)
	})

	const classes = useStyles()
	return <IdleTimer ref={(ref: any) => { _idleTimerRef = ref }} timeout={TIMEOUT} onIdle={handleIdle}>
		<Box className={classes.appWrapper}>
			<AppBar position="static">
				<Toolbar />
			</AppBar>
			<MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={"de-at"}>
				<LoadingContextProvider className={classes.loadingWrapper} loadingComponent={LoadingBarIndicator}>
					<MembersContextProvider>
						<CourtsContextProvider>
							<ReservationContextProvider>
								<ReservationDialogProvider>
									<MultiResDialogProvider>
										<Box className={classes.contentWrapper} >
											<Switch>
												<Route path="/login" exact={true}>
													{
														user ? <Redirect to="/" /> :
															<LoginForm login={login} />
													}
												</Route>
												<AuthenticatedRoute path="/users" exact component={UserList} />
												<AuthenticatedRoute path="/users/:userId" exact component={UserRoute} />
												<Route path="/tagesansicht">
													<SingleDayViewActivation />
												</Route>
												<Route path="/">
													<ResInfoSelectionProvider>
														<ReservationView today={today} />
													</ResInfoSelectionProvider>
												</Route>
											</Switch>
										</Box>

									</MultiResDialogProvider>
								</ReservationDialogProvider>
							</ReservationContextProvider>
						</CourtsContextProvider>
					</MembersContextProvider>
				</LoadingContextProvider>
			</MuiPickersUtilsProvider>
		</Box>
	</IdleTimer>
}

export default MainNav