import React, { useState, useCallback, useEffect } from "react"
import DaySelector from "../../DaySelector"
import Box from "@material-ui/core/Box"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Button from "@material-ui/core/Button"
import { createStyles, makeStyles } from "@material-ui/core/styles"
import CourtsView from "../../CourtsView"
import { ReservationViewVariantProps } from "./ReservationViewVariantProps"
import SwipeableViews from "react-swipeable-views"
import { virtualize, SlideRendererCallback } from 'react-swipeable-views-utils';
import moment from "moment"
import { useReservationsInfo } from "../../../hooks"
import { useResInfoSelection } from "../../../context"
import Divider from "@material-ui/core/Divider"

const VirtualizeSwipeableViews = virtualize(SwipeableViews)

const useStyles = makeStyles((theme) => createStyles({
	reservationWrapper: {
		display: "flex",
		flex: 1,
		flexDirection: "column",
		maxWidth: "100%",
		marginBottom: theme.spacing(2)
	},
	swipeView: {
		display: "flex",
		flex: 1,
		minWidth: 0,
		maxWidth: "100%"
	}
}
))

const overscanBefore = 1
const overscanAfter = 1

function SingleDayView(props: ReservationViewVariantProps) {
	const {
		today: todayProp, courts, reservations, fetch, onInfoClick, user, multiResClick, multiCancelClick, clearSelClick, verbose
	} = props

	const today = moment(todayProp).startOf("day").toDate()

	const [index, setIndex] = useState(0)

	const calcDateForIndex = useCallback((index: number) => moment(today).add(index, "days").toDate(), [today])

	const date = calcDateForIndex(index)
	const showFromMS = moment(date).startOf("day").add(-overscanBefore, "days").valueOf()
	const showToMS = moment(date).endOf("day").add(overscanAfter, "days").valueOf()
	const { multiSelectEnabled, toggleMultiSelect, selected } = useResInfoSelection()

	const setSelectedDay = useCallback((date: Date) => {
		setIndex(moment(date).diff(today, "days"))
	}, [today])

	useEffect(() => {
		fetch(
			new Date(showFromMS),
			new Date(showToMS)
		)
	}, [showFromMS, showToMS, fetch])

	const reservationInfo = useReservationsInfo({
		allReservations: reservations,
		courts,
		showFromMS,
		showToMS
	})

	const classes = useStyles()

	const slideRenderer: SlideRendererCallback = ({ index, key }) =>
		<CourtsView key={key}
			user={user}
			verbose={verbose}
			forDate={calcDateForIndex(index)} courts={courts}
			reservationInfo={reservationInfo}
			onInfoClick={onInfoClick} />

	return (
		<Box className={classes.reservationWrapper}>
			<DaySelector selectedDay={date}
				onDaySelected={setSelectedDay}
				today={today}
			/>
			<Divider />
			<VirtualizeSwipeableViews className={classes.swipeView}
				containerStyle={{
					flex: 1,
					maxWidth: "100%"
				}}
				slideStyle={{
					display: "flex"
				}}
				overscanSlideAfter={overscanBefore}
				overscanSlideBefore={overscanAfter}
				onChangeIndex={setIndex}
				index={index}
				slideRenderer={slideRenderer}
			/>
			{user?.role?.multiReservation && <Box
				display="flex"
				justifyContent="center"
				flexWrap="wrap"
				alignItems="center">
				<FormControlLabel
					control={<Checkbox color="primary" checked={multiSelectEnabled} onChange={toggleMultiSelect} />}
					label="Multi"
				/>
				<Button variant="contained" color="primary" disabled={selected.length <= 0} onClick={multiResClick}>{selected.length} reservieren</Button>
				<Button variant="contained" color="primary" disabled={selected.length <= 0} onClick={multiCancelClick}>Stornieren</Button>
				<Button variant="contained" color="secondary" disabled={selected.length <= 0} onClick={clearSelClick}>Auswahl löschen</Button>
			</Box>}
		</Box>
	)
}

export default React.memo(SingleDayView)