import React, { createContext, useRef, useState, useCallback } from "react"
import ResponsiveDialog from "./components/ResponsiveDialog"
import { Member, Reservation, ReservationInfo } from "../../api"
import { useAuth, useConfirmation, useShowMessage, useMembers } from "../../context"
import useReservationRules from "../../hooks/useReservationRules"

export interface ReservationDialogOptions {
	reservationInfo: ReservationInfo,
	saveReservation: (reservation: Reservation) => void,
	cancelReservation: (reservation: Reservation) => void
}

const ReservationDialogContext = createContext<(options: ReservationDialogOptions) => void>(() => {
})

export const ReservationDialogProvider = ({ children }: { children: React.ReactNode }) => {
	const [options, setOptions] = useState<ReservationDialogOptions>()
	const closeDialog = useCallback(() => setOptions(undefined), [])

	const [player2, setPlayer2] = useState<Member | null>(null)
	const [description, setDescription] = useState("")

	const allMembers = useMembers()

	const { user } = useAuth()
	const showMessage = useShowMessage()

	const awaitingPromiseRef = useRef<{
		resolve: (value: void | PromiseLike<void>) => void;
		reject: (reason?: any) => void;
	}>()

	const showReservationDialog = useCallback((options: ReservationDialogOptions): Promise<void> => {
		setOptions(options)
		return new Promise((resolve, reject) => {
			awaitingPromiseRef.current = { resolve, reject }
		})
	}, [])

	const { requiresPlayerSelection, canEnterDescription, mustEnterDescription} = useReservationRules()
	const saveReservation = useCallback(() => {
		if (!options || !user) return

		const { ...savingUser } = user

		const resInfo = options.reservationInfo

		if (!player2 && requiresPlayerSelection)
			return showMessage({
				message: "Bitte einen zweiten Spieler auswählen",
				severity: "warning",
				duration: 3000
			})

		const players = []
		if (requiresPlayerSelection) {
			players.push(savingUser)
			if (player2)
				players.push(player2)
		}

		if(mustEnterDescription && (description || "").length < 3)
		return showMessage({
			// Ugly as hell but good for now
			message:  user.roleId === "G" ? "Bitte gebt eure Namen ins Bezeichnungsfeld ein!": "Eine Bezeichnung muss eingegeben werden!",
			severity: "warning",
			duration: 3000
		})

		options.saveReservation({
			reservedBy: savingUser,
			hour: resInfo.hour,
			courtId: resInfo.courtId,
			reservedAt: new Date(),
			description: canEnterDescription ? description : undefined,
			players
		})

		closeDialog()
	}, [closeDialog, options, player2, showMessage, user, description, canEnterDescription, mustEnterDescription, requiresPlayerSelection])

	const confirm = useConfirmation()

	const onRequestCancel = useCallback(() => {
		const reservation = options?.reservationInfo?.reservation
		if (!options || !reservation || !user) return

		confirm({
			message: "Willst du diese Reservierung wirklich stornieren?"
		}).then(() => {
			closeDialog()
			options.cancelReservation(reservation)
		})
	}, [closeDialog, confirm, options, user])

	return (<React.Fragment>
		<ReservationDialogContext.Provider
			value={showReservationDialog}
			children={children}
		/>
		{!options ? <div /> :
			<ResponsiveDialog open={!!options}
				onClose={closeDialog}
				onSave={saveReservation}
				reservationInfo={options.reservationInfo}
				onRequestCancel={onRequestCancel}
				description={description}
				onDescriptionChanged={setDescription}
				player2={player2}
				onPlayer2Selected={setPlayer2}
				membersToSelectFrom={allMembers} />}
	</React.Fragment>)

}

export const useShowReservationDialog = () => React.useContext(ReservationDialogContext)
