export class ReservationError extends Error {
	constructor(error: string) {

		//could be changed to include all
		// assuming the first is the most relevant though
		super(error)

		// Set the prototype explicitly.
		Object.setPrototypeOf(this, ReservationError.prototype)
	}
}