import React, { useCallback, useEffect, useState } from "react"
import moment from "moment"
import SwipeableViews from "react-swipeable-views"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import ChevronRight from "@material-ui/icons/ChevronRight"
import Divider from "@material-ui/core/Divider"
import Week from "./Week"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import { virtualize, SlideRendererCallback } from 'react-swipeable-views-utils';
import { useReWake } from "../../hooks"

const VirtualizeSwipeableViews = virtualize(SwipeableViews)

type DaySelectorProps = {
	selectedDay: Date,
	today: Date,
	onDaySelected: (day: Date) => void
}

const useStyles = makeStyles((theme: Theme) => createStyles({
	outer: {
		display: "flex",
		flexDirection: "column"
	},
	selectorWrapper: {
		display: "flex",
		width: "100%",
		minWidth: 0,
		flexDirection: "row",
		minHeight: 42,
		"&:first-child": {
			paddingRight: theme.spacing(0.5)
		},
		"&:last-child": {
			paddingLeft: theme.spacing(0.5)
		}
	},
	sliderWrapper: {
		flex: 1,
		minWidth: 0
	},
	backButton: {
		padding: 0
	},
	nextButton: {
		padding: 0
	}
}
))

const DaySelector = (props: DaySelectorProps) => {
	const { selectedDay, onDaySelected, today } = props
	const classes = useStyles(props)

	const [index, setIndex] = useState<number>(0)

	const getMomentForIndex = (index: number) => moment(today).add(index, "weeks")

	useEffect(() => {
		setIndex(moment(selectedDay).week() - moment(today).week())
	}, [selectedDay, today])

	const showTodayButton = index !== 0
		|| selectedDay.getFullYear() !== today.getFullYear()
		|| selectedDay.getMonth() !== today.getMonth()
		|| selectedDay.getDate() !== today.getDate()

	const slideRenderer: SlideRendererCallback = ({ index, key }) =>
		<Week key={key} weekNr={getMomentForIndex(index).week()}
			year={getMomentForIndex(index).get("year")}
			selectedDay={selectedDay}
			onDaySelected={onDaySelected} />

	const handleShowToday = useCallback(() => {
		onDaySelected(today)
		setIndex(0)
	}, [onDaySelected, setIndex, today])

	// useReWake(handleShowToday)

	return <Box className={classes.outer}>
		<Box className={classes.selectorWrapper}>
			<IconButton className={classes.backButton}
				onClick={() => setIndex(cs => cs - 1)}>
				<ChevronLeft />
			</IconButton>
			<Box className={classes.sliderWrapper}>
				<VirtualizeSwipeableViews
					overscanSlideAfter={3}
					overscanSlideBefore={3}
					onChangeIndex={setIndex}
					index={index}
					slideRenderer={slideRenderer}
				/>
			</Box>
			<IconButton className={classes.nextButton}
				onClick={() => setIndex(cs => cs + 1)}>
				<ChevronRight />
			</IconButton>
		</Box>
		{showTodayButton &&
			<React.Fragment>
				<Divider />
				<Button onClick={handleShowToday}>Heute anzeigen</Button>
			</React.Fragment>}
	</Box>
}

export default React.memo(DaySelector)