import React, { createContext, useRef, useState, useCallback } from "react"
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export interface SingleInputDialogOptions {
	title: string,
	inputName: string
}

const SingleInputDialogContext = createContext<(options: SingleInputDialogOptions) => Promise<string>>(() => Promise.reject())

export const SingleInputDialogProvider = ({ children }: { children: React.ReactNode }) => {
	const [options, setOptions] = useState<SingleInputDialogOptions>()

	const [description, setDescription] = useState("")

	const awaitingPromiseRef = useRef<{
		resolve: (value: string | PromiseLike<string>) => void;
		reject: (reason?: any) => void;
	}>()

	const showSingleInputDialog = useCallback((options: SingleInputDialogOptions): Promise<string> => {
		setOptions(options)
		return new Promise<string>((resolve, reject) => {
			awaitingPromiseRef.current = { resolve, reject }
		})
	}, [])

	const handleClose = useCallback(
		() => {
			awaitingPromiseRef.current?.reject()
			setOptions(undefined)
		},
		[awaitingPromiseRef, setOptions],
	)

	const hanldeOk = useCallback(
		() => {
			awaitingPromiseRef.current?.resolve(description)
			setOptions(undefined)
		},
		[awaitingPromiseRef, description],
	)

	return (<React.Fragment>
		<SingleInputDialogContext.Provider
			value={showSingleInputDialog}
			children={children}
		/>
		{!options ? null :
			<Dialog open={!!options} onClose={handleClose} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">{options.title}</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						label={options.inputName}
						value={description}
						onChange={(e) => setDescription(e.target.value)}
						fullWidth
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Abbrechen
          </Button>
					<Button onClick={hanldeOk} color="primary">
						Reservieren
          </Button>
				</DialogActions>
			</Dialog>
		}

	</React.Fragment>)

}

export const useSingleInputDialog = () => React.useContext(SingleInputDialogContext)
