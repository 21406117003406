import React, { useState } from "react"
import MuiDialog from "@material-ui/core/Dialog"
import Button from "@material-ui/core/Button"

import ReservationContent from "./Content"
import Toolbar from "./Toolbar"
import getReservationTitleByRole from "../../../tools/getReservationTitleByRole"
import { ReservationDialogProps } from "../../ReservationDialogProps"
import { SearchMemberDialog } from "./SearchMemberDialog"
import { Member } from "../../../../../api"


const Dialog = (props: ReservationDialogProps) => {
	const { open, onClose, reservationInfo, onRequestCancel, onSave, membersToSelectFrom, onPlayer2Selected } = props

	const [membersDialogOpen, setMembersDialogOpen] = useState<boolean>(false)

	const memberSelected = (member: Member) => {
		setMembersDialogOpen(false)
		onPlayer2Selected(member)
	}

	return <MuiDialog
		fullScreen={true}
		open={open}
		onClose={onClose}
	>
		<Toolbar navButtonPressed={onClose}
			text={getReservationTitleByRole(reservationInfo.reservation?.reservedBy?.roleId)}>
			{!reservationInfo.cancellationError && onRequestCancel &&
				<Button style={{ textTransform: "capitalize" }} variant="contained"
					color="secondary" size="small"
					onClick={onRequestCancel}>Stornieren</Button>}
			{!reservationInfo.reservationError && !reservationInfo?.reservation && onSave &&
				<Button style={{ textTransform: "capitalize" }} variant="contained"
					color="secondary" size="small"
					onClick={onSave}>Speichern</Button>}
		</Toolbar>
		<ReservationContent {...props} onRequestSelectPlayer2={() => setMembersDialogOpen(true)} />
		<SearchMemberDialog
			allMembers={membersToSelectFrom}
			open={membersDialogOpen}
			onClose={() => setMembersDialogOpen(false)}
			selectMember={memberSelected}
		/>
	</MuiDialog>
}

export default Dialog