import React, { createContext, useState, useEffect } from "react"
import { Court, CourtApi } from "../api"
import { useShowMessage } from "./MessageContext"
import { useLoading } from "./LoadingContext"

const CourtsContext = createContext<Court[]>([])

interface CourtsContextProviderOptions {
    children: React.ReactNode
}

export const CourtsContextProvider = ({ children }: CourtsContextProviderOptions) => {
    const [courts, setCourts] = useState<Court[]>([])

    const showMessage = useShowMessage()
    const { indicateLoading } = useLoading()

    useEffect(() => {
        CourtApi.getCourts()
            .then(setCourts)
            .catch(() => showMessage({
                message: "Fehler beim laden",
                severity: "error"
            }))
            .finally(indicateLoading())
    }, [showMessage, indicateLoading])

    return <CourtsContext.Provider value={courts} children={children} />
}

export const useCourts = () => React.useContext(CourtsContext)
