import React, { useMemo, useState } from "react"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import Avatar from "@material-ui/core/Avatar"
import ListItemText from "@material-ui/core/ListItemText"

import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme, makeStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog"

import Toolbar from "./Toolbar"
import formatName from "../../../tools/formatName"
import { Member } from "../../../../../api"

import { AutoSizer, List, ListRowRenderer } from "react-virtualized"

type SearchUserDialogPorps = {
	open: boolean
	onClose: () => void,
	selectMember: (member: Member) => void,
	allMembers: Member[]
}

const MemberListItem = ({ member, selectMember, style }: { member: Member, selectMember: (member: Member) => void, style: any }) =>
	<ListItem onClick={() => selectMember(member)} style={style}>
		<ListItemIcon>
			<Avatar>{member.firstName?.slice(0, 1)?.toUpperCase() || ""}{member.lastName?.slice(0, 1)?.toUpperCase() || ""}</Avatar>
		</ListItemIcon>
		<ListItemText primary={formatName(member)} />
	</ListItem>

const memberSortComparer = (a: Member, b: Member): number => {
	let result = b.playedWithMe - a.playedWithMe
	if (result !== 0) return result
	result = (a.firstName || "").localeCompare(b.firstName || "")
	if (result !== 0) return result
	result = (a.lastName || "").localeCompare(b.lastName || "")

	return result
}


const useStyles = makeStyles((theme) => ({
	searchDialog: {
		[theme.breakpoints.up("lg")]: {
			width: 400,
			height: 600
		}
	}
}))


export const SearchMemberDialog = (props: SearchUserDialogPorps) => {
	const { open, onClose, selectMember, allMembers } = props
	const [searchText, setSearchText] = useState<string>("")

	const membersToDisplay = useMemo(() => allMembers
		.filter(member => `${member.firstName || ""}${member.lastName || ""}`.toLowerCase().includes(searchText.toLowerCase()))
		.sort(memberSortComparer), [allMembers, searchText])

	const renderRow: ListRowRenderer = ({ key, style, index }) => {
		const member = membersToDisplay[index]
		return <MemberListItem
			key={key}
			member={member}
			selectMember={selectMember}
			style={style}
		/>
	}
	const theme = useTheme()
	const fullScreenDialogs = useMediaQuery(theme.breakpoints.down("md"))

	const classes = useStyles()
	return <Dialog
		fullScreen={fullScreenDialogs}
		open={open}
		onClose={onClose}
		classes={{ paper: classes.searchDialog }}
	>
		<Toolbar navButtonPressed={onClose} text={searchText}
			closeIcon="chevron"
			textChange={{
				onTextChanged: setSearchText,
				placeholder: "Suchen..."
			}}>
		</Toolbar>
		<AutoSizer>
			{({ height, width }) => <List

				ref="MemberList"
				height={height}
				width={width}
				overscanRowCount={15}
				rowCount={membersToDisplay.length}
				rowHeight={56}
				rowRenderer={renderRow} />}

		</AutoSizer>
	</Dialog>
}

