import { Reservation } from "./ReservationApi"

export type ReservationInfo = {
	hour: Date,
	courtId: number,
	reservationError: string | undefined,
	reservation?: Reservation,
	cancellationError: string | undefined,
	violation?: string
}

export const isSameReservationInfo = (a: ReservationInfo, b: ReservationInfo): boolean =>
	a.courtId === b.courtId
	&& a.hour.getTime() === b.hour.getTime()
	&& a.reservationError === b.reservationError
	&& a.cancellationError === b.cancellationError
	&& !!a.reservation === !!b.reservation // changing a reservation is rather rare, so no deep compare here
