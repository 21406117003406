import React from 'react'
import Hidden from '@material-ui/core/Hidden'

import MobileDialog from '../Mobile'
import LargeScreenDialog from '../LargeScreen'
import { ReservationDialogProps } from '../ReservationDialogProps'

const ResponsiveDialog = (props: ReservationDialogProps) => <React.Fragment>
    <Hidden lgUp>
        <MobileDialog {...props} />
    </Hidden>
    <Hidden mdDown>
        <LargeScreenDialog {...props} />
    </Hidden>
</React.Fragment>

export default ResponsiveDialog