import React, { createContext, useState } from "react"
import { ReservationInfo } from "../api"

export type ResInfoSelectionContextValue = {
    selected: ReservationInfo[]
    multiSelectEnabled: boolean
    toggleMultiSelect: () => void
    toggleSelection: (info: ReservationInfo) => void
    bulkSelect: (infos: ReservationInfo[], selected: boolean) => void,
    isSelected: (info: ReservationInfo) => boolean
    clearSelection: () => void
}

const ResInfoSelectionContext = createContext<ResInfoSelectionContextValue>({
    selected: [],
    multiSelectEnabled: false,
    toggleMultiSelect: () => { },
    toggleSelection: () => { },
    bulkSelect: () => { },
    isSelected: () => false,
    clearSelection: () => { }
})


const createFilter = (info: ReservationInfo) => (existing: ReservationInfo): boolean => existing.hour.getTime() === info.hour.getTime() && existing.courtId === info.courtId

export const ResInfoSelectionProvider = ({ children }: { children: React.ReactNode }) => {
    const [selected, setSelected] = useState<ReservationInfo[]>([])
    const [multiSelectEnabled, setMultiSelectEnabled] = useState(false)

    const clearSelection = () => setSelected([])

    const toggleMultiSelect = () => setMultiSelectEnabled(mse => {
        // When un-toggling multi select un-select all
        if (mse)
            clearSelection()
        return !mse
    })

    const toggleSelection = (info: ReservationInfo) => setSelected(oldSelection => {
        const filter = createFilter(info)
        if (oldSelection.find(filter))
            return oldSelection.filter(ex => !filter(ex))
        return [...oldSelection, info]
    })

    const isSelected = (info: ReservationInfo) => {
        const filter = createFilter(info)
        return !!selected.find(filter)
    }

    const bulkSelect = (infos: ReservationInfo[], selected: boolean = true) => setSelected(oldSelection => {


        const filtered = oldSelection.filter(oldSelected => {
            const filter = createFilter(oldSelected)
            return !infos.find(i => filter(i))
        })

        return !selected ?
            filtered :
            [...filtered, ...infos]
    })

    return (<React.Fragment>
        <ResInfoSelectionContext.Provider
            value={{ selected, toggleSelection, isSelected, multiSelectEnabled, toggleMultiSelect, clearSelection, bulkSelect }}
            children={children}
        />
    </React.Fragment>)

}

export const useResInfoSelection = () => React.useContext(ResInfoSelectionContext)
