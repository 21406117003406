import React from 'react'

import MuiListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Divider from "@material-ui/core/Divider"

type ListItemProps = {
	divider?: boolean,
	title: string,
	value: string,
	icon: React.ReactElement,
	highlight?: boolean,
	onClick?: () => void
}

const ListItem = ({ divider, title, value, icon, highlight, onClick }: ListItemProps) =>
	<React.Fragment>
		{divider && <Divider />}
		<MuiListItem style={{
			color: highlight ? "red" : undefined,
			cursor: onClick ? "pointer" : undefined
		}}
			onClick={onClick}>
			<ListItemIcon>
				{icon}
			</ListItemIcon>
			<ListItemText primary={title}
				secondary={value} />
		</MuiListItem>
	</React.Fragment>

export default ListItem