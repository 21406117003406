import { AuthError } from "./AuthError"
import { User } from "./User"
import { ReservationError } from "./ReservationError"
import { ReservationInfo } from "./ReservationInfo"

export type Reservation = {
	hour: Date,
	courtId: number,
	reservedBy: { id: string, roleId: string, firstName?: string, lastName?: string },
	reservedAt: Date,
	description?: string,
	players: { id: string, firstName?: string, lastName?: string }[]
}

export const parseApiReservationResponse = (value: any): Reservation => ({
	...value,
	hour: new Date(value.hour),
	reservedAt: new Date(value.reservedAt)
})

export const sameResFilter = (b: Reservation) => (a: Reservation) => a.hour.getTime() === b.hour.getTime() && a.courtId === b.courtId

export class ReservationApi {

	static parseApiReservationResponse(value: any): Reservation {
		return ({
			...value,
			hour: new Date(value.hour),
			reservedAt: new Date(value.reservedAt)
		})
	}

	static async getReservations(from: Date, to?: Date): Promise<Reservation[]> {

		const url = new URL("./reservations", process.env.REACT_APP_API_BASE_URL)
		const searchParams = new URLSearchParams()
		searchParams.append("from", from.toISOString())

		if (to) searchParams.append("to", to.toISOString())
		const data = await fetch(`${url.toString()}?${searchParams.toString()}`).then(res => res.json())
		return data.map(parseApiReservationResponse)
	}

	static async cancelReservation(user: User, reservation: Reservation): Promise<void> {
		const url = new URL("./reservations", process.env.REACT_APP_API_BASE_URL)
		const searchParams = new URLSearchParams()
		searchParams.append("courtId", reservation.courtId.toString())
		searchParams.append("hour", reservation.hour.toISOString())

		const response = await fetch(`${url.toString()}?${searchParams.toString()}`, {
			headers: new Headers({
				"Authorization": `Bearer ${user.token}`
			}),
			method: "DELETE"
		})
		switch (response.status) {
			case 204:
				break
			case 401:
				throw new AuthError()
			case 400:
				throw new ReservationError(await response.text())
			default:
				throw new Error(response.status + " " + response.statusText)
		}
	}

	private static async doReservation(user: User, reservation: Reservation, options?: { dryRun?: boolean }): Promise<Reservation> {
		const url = new URL("./reservations", process.env.REACT_APP_API_BASE_URL)
		const searchParams = new URLSearchParams()
		if (options?.dryRun)
			searchParams.append("dryRun", "true")

		const response = await fetch(`${url.toString()}?${searchParams.toString()}`, {
			headers: new Headers({
				"Authorization": `Bearer ${user.token}`,
				"Content-Type": "application/json"
			}),
			body: JSON.stringify(reservation),
			method: "POST"
		})
		switch (response.status) {
			case 200:
				break
			case 401:
				throw new AuthError()
			case 400:
				throw new ReservationError(await response.text())
			default:
				throw new Error(response.status + " " + response.statusText)
		}

		const json = await response.json()
		return parseApiReservationResponse(json)
	}

	static async multiReservation(user: User, resinfo: ReservationInfo[], description: string, cancel: boolean): Promise<{ cancelled: Reservation[], reserved: Reservation[] }> {
		const url = new URL("./reservations/multi", process.env.REACT_APP_API_BASE_URL)

		const response = await fetch(`${url.toString()}`, {
			headers: new Headers({
				"Authorization": `Bearer ${user.token}`,
				"Content-Type": "application/json"
			}),
			body: JSON.stringify({
				description,
				cancel,
				reservations: resinfo.map(ri => ({ hour: ri.hour, courtId: ri.courtId }))
			}),
			method: "POST"
		})
		switch (response.status) {
			case 200:
				break
			case 401:
				throw new AuthError()
			case 400:
				throw new ReservationError(await response.text())
			default:
				throw new Error(response.status + " " + response.statusText)
		}

		const json = await response.json()
		json.cancelled = json.cancelled.map(parseApiReservationResponse)
		json.reserved = json.reserved.map(parseApiReservationResponse)
		return json
	}

	static async saveReservation(user: User, reservation: Reservation): Promise<Reservation> {
		return this.doReservation(user, reservation)
	}
}