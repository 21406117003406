import { User } from "./User"
import md5 from "md5"
export interface ProfileListEntry {
    userId: string;
    active: boolean;
    firstName?: string;
    lastName: string;
    roleId: string;
}

export interface Profile extends ProfileListEntry {
    salutation?: string;
    password?: string;
    changePassword?: boolean;
    title?: string;
    sex?: string;
    birthDate?: Date;
    zipCode?: number;
    city?: string;
    street?: string;
    mobile?: string;
    sttvLink?: string;
    sttvLicence?: number;
    sendPost: boolean;
    sendSms: boolean;
    sendEmail: boolean;
}

const parseProfile = (raw: any): Profile => {
    const parsed = { ...raw }

    if (raw.birthDate !== undefined) {
        const bdTimestamp = Date.parse(raw.birthDate)
        parsed.birthDate = isNaN(bdTimestamp) ? null : new Date(bdTimestamp)
    }
    return parsed
}

const toBody = (profile: Partial<Profile>): string => {
    const prepared: any = { ...profile }
    if (profile.birthDate)
        prepared.birthDate = `${profile.birthDate.getFullYear()}-${profile.birthDate.getMonth() + 1}-${profile.birthDate.getDate()}`

    if (profile.changePassword)
        prepared.password = md5(profile.password || "")

    delete prepared.changePassword

    return JSON.stringify(prepared)
}

class ProfileApi {
    static listProfiles(user: User): Promise<ProfileListEntry[]> {
        return fetch(new URL("./profiles", process.env.REACT_APP_API_BASE_URL).toString(), {
            headers: new Headers({
                "Authorization": `Bearer ${user.token}`
            }),
            method: "GET"
        })
            .then(res => res.json())
    }

    public static async getProfile(user: User, userId: string): Promise<Profile | undefined> {
        const response = await fetch(new URL(`./profiles/${userId}`, process.env.REACT_APP_API_BASE_URL).toString(), {
            headers: new Headers({
                "Authorization": `Bearer ${user.token}`
            }),
            method: "GET"
        })
        if (response.status === 404)
            return undefined

        const body = await response.json()
        const profile = parseProfile(body)
        return profile
    }

    public static async updateProfile(user: User, profile: Partial<Profile>) {
        const updateProf = { ...profile }
        delete updateProf.userId
        const result = await fetch(new URL(`./profiles/${profile.userId}`, process.env.REACT_APP_API_BASE_URL).toString(), {
            headers: new Headers({
                "Authorization": `Bearer ${user.token}`,
                "Content-Type": "application/json"
            }),
            body: toBody(updateProf),
            method: "PATCH",
        })
        if (result.status < 200 || result.status >= 300)
            throw new Error(`${result.status}: ${result.statusText}`)
    }

    public static async createProfile(user: User, profile: Profile) {
        const result = await fetch(new URL(`./profiles`, process.env.REACT_APP_API_BASE_URL).toString(), {
            headers: new Headers({
                "Authorization": `Bearer ${user.token}`,
                "Content-Type": "application/json"
            }),
            body: toBody(profile),
            method: "POST",
        })
        if (result.status < 200 || result.status >= 300) {
            if (result.status === 400) {
                const resText = await result.text()
                throw new Error(resText)
            }
            throw new Error()
        }
    }

    public static async deleteProfile(user: User, userId: string) {
        const result = await fetch(new URL(`./profiles/${userId}`, process.env.REACT_APP_API_BASE_URL).toString(), {
            headers: new Headers({
                "Authorization": `Bearer ${user.token}`
            }),
            method: "DELETE",
        })
        if (result.status < 200 || result.status >= 300) {
            if (result.status === 400) {
                const resText = await result.text()
                throw new Error(resText)
            }
            throw new Error()
        }
    }
}

export { ProfileApi }