export class DateTools {
	public static getDateOnly(date: Date): Date {
		return new Date(date.getFullYear(), date.getMonth(), date.getDate())
	}

	public static getDaysBetween(from: Date, to: Date): Date[] {
		const start = DateTools.getDateOnly(from)
		const days: Date[] = []
		do {
			days.push(new Date(start))
			start.setDate(start.getDate() + 1)
		} while (start < to)
		return days
	}
}