import React from 'react'
import moment from 'moment'

import Dialog from '@material-ui/core/Dialog'
import { makeStyles } from '@material-ui/core/styles'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

import CloseIcon from '@material-ui/icons/Close'
import ScheduleIcon from '@material-ui/icons/Schedule'
import TennisIcon from '@material-ui/icons/SportsTennis'
import PersonIcon from "@material-ui/icons/Person"
import DescriptionIcon from "@material-ui/icons/Description"

import { ReservationDialogProps } from '../ReservationDialogProps'
import getReservationTitleByRole from '../../tools/getReservationTitleByRole'
import formatName from '../../tools/formatName'
import { useAuth } from '../../../../context'
import useReservationRules from '../../../../hooks/useReservationRules'
import { DialogContentArea } from './DialogContentArea'
import MemberSelection from './MemberSelection'

const useStyles = makeStyles((theme) => ({
    dialog: {
        minWidth: 400,
        maxWidth: "24em",
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1)
    },
    content: {
        display: "flex",
        flexDirection: "column",
        paddingBottom: theme.spacing(3)
    }
}))

const LgDialog = (props: ReservationDialogProps) => {
    const { open, onClose, reservationInfo, onRequestCancel, onDescriptionChanged, description, onSave, player2, onPlayer2Selected, membersToSelectFrom } = props
    const { reservation } = reservationInfo
    const classes = useStyles(props)

    const { user } = useAuth()
    const { requiresPlayerSelection, canEnterDescription } = useReservationRules()

    return <Dialog
        open={open}
        onClose={onClose}
        classes={{ paper: classes.dialog }}
    >
        <DialogTitle id="simple-dialog-title">
            {getReservationTitleByRole(reservationInfo.reservation?.reservedBy?.roleId)}

            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent className={classes.content}>
            <DialogContentArea accent={<ScheduleIcon />}>
                <Typography variant="body1">{moment(reservationInfo.hour).format("HH:mm, dddd D. MMMM")}</Typography>
            </DialogContentArea>
            <DialogContentArea accent={<TennisIcon />}>
                <Typography variant="body1">Platz {reservationInfo.courtId}</Typography>
            </DialogContentArea>
            {reservation && reservation.players.length === 0 &&
                <DialogContentArea accent={<PersonIcon />}>
                    <Typography key={reservation.reservedBy.id} variant="body1" noWrap={true}>{formatName(reservation.reservedBy)}</Typography>
                </DialogContentArea>}
            {reservation && reservation.players.length > 0 &&
                <DialogContentArea accent={<PersonIcon />}>
                    {reservation.players.map(player =>
                        <Typography key={player.id} variant="body1" noWrap={true}>{formatName(player)}</Typography>
                    )}
                </DialogContentArea>}
            {!reservation && requiresPlayerSelection && user &&
                <React.Fragment>
                    <DialogContentArea accent={<PersonIcon />}>
                        <Typography variant="body1" noWrap={true}>{formatName(user)}</Typography>
                    </DialogContentArea>
                    <DialogContentArea accent={<PersonIcon />}>
                        <MemberSelection allMembers={membersToSelectFrom} selectedMember={player2} onSelectionChanged={onPlayer2Selected} />
                    </DialogContentArea>
                </React.Fragment>}
            {(((reservation?.description?.length || 0) > 0) || (!reservation && canEnterDescription)) &&
                <DialogContentArea accent={<DescriptionIcon />}>
                    {!reservation && onDescriptionChanged ?
                        <TextField placeholder="Bezeichnung" multiline={true} value={description} onChange={(e) => onDescriptionChanged(e.target.value)} /> :
                        <Typography variant="body1">{reservation?.description}</Typography>}
                </DialogContentArea>}
        </DialogContent>
        {((!reservationInfo.cancellationError && onRequestCancel) || (!reservation && onSave)) && <Divider />}
        <DialogActions>
            {!reservationInfo.cancellationError && onRequestCancel &&
                <React.Fragment>
                    <Divider />
                    <Button onClick={onRequestCancel} color="primary">Stornieren</Button>
                </React.Fragment>}
            {!reservation && onSave && <React.Fragment>
                <Divider />
                <Button autoFocus onClick={onSave} color="primary">Speichern</Button>
            </React.Fragment>}
        </DialogActions>
    </Dialog>
}

export default LgDialog