import React from "react"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { ReservationViewVariantProps } from "./ReservationViewVariantProps"
import SingleDayView from "./SingleDayView"
import WeekView from "./WeekView"
import { useClubHouse } from "../../../context"

const ResponsiveReservationView = (props: ReservationViewVariantProps) => {
	const theme = useTheme()
	const isLarge = useMediaQuery(theme.breakpoints.up("lg"))
	const { inClubMode } = useClubHouse()

	return inClubMode ? <SingleDayView {...props} verbose /> :
		isLarge ? <WeekView {...props} /> : <SingleDayView {...props} />
}

export default ResponsiveReservationView