import React, { createContext, useState, useCallback } from "react"

interface LoadingContextValue {
    indicateLoading: () => () => void
}

interface LoadingBaseProps {
    children: React.ReactNode,
    className?: string,
    style?: React.CSSProperties
}

export interface LoadingComponentProps extends LoadingBaseProps {
    loadingCount: number,
}
interface LoadingContextProviderProps extends LoadingBaseProps {
    loadingComponent: React.FunctionComponent<LoadingComponentProps> | React.ComponentClass<LoadingComponentProps>
}

const LoadingContext = createContext<LoadingContextValue>({
    indicateLoading: () => () => { },
})

export const LoadingContextProvider = ({ children, loadingComponent, ...componentProps }: LoadingContextProviderProps) => {
    const [loadingCount, setLoadingCount] = useState(0)

    const indicateLoading = useCallback(() => {
        setTimeout(() => setLoadingCount(old => old + 1), 300)
        
        return () => setLoadingCount(old => old - 1)
    }, [])

    return <LoadingContext.Provider value={{
        indicateLoading
    }}>
        {React.createElement(loadingComponent, { ...componentProps, loadingCount, children }, children)}
    </LoadingContext.Provider>
}

export const useLoading = () => React.useContext(LoadingContext)
