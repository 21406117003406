import React, { createContext, useMemo, useRef, useState } from "react"
import ConfirmationDialog from "../components/ConfirmationDialog"

export type ConfirmationOptions = {
	message: string,
	title?: string,
	confirmText?: string,
	cancelText?: string,
	catchOnCancel?: boolean
}

const ConfirmationServiceContext = createContext<(confirmationOptions: ConfirmationOptions) => Promise<void>>(Promise.reject)

export const ConfirmationServiceProvider = ({ children }: { children: React.ReactNode }) => {
	const [confirmationState, setConfirmationState] = useState<ConfirmationOptions>()

	const awaitingPromiseRef = useRef<{
		resolve: () => void;
		reject: () => void;
	}>()

	const openConfirmation = useMemo(() => (options: ConfirmationOptions): Promise<void> => {
		setConfirmationState(options)
		return new Promise((resolve, reject) => {
			awaitingPromiseRef.current = { resolve, reject }
		})
	}, [setConfirmationState])

	const handleClose = () => {
		if (confirmationState?.catchOnCancel && awaitingPromiseRef.current) {
			awaitingPromiseRef.current.reject()
		}

		setConfirmationState(undefined)
	}

	const handleSubmit = () => {
		if (awaitingPromiseRef.current) {
			awaitingPromiseRef.current.resolve()
		}

		setConfirmationState(undefined)
	}

	return (<React.Fragment>
		<ConfirmationServiceContext.Provider
			value={openConfirmation}
			children={children}
		/>

		<ConfirmationDialog
			open={!!confirmationState}
			message={confirmationState?.message || ""}
			{...confirmationState}
			onSubmit={handleSubmit}
			onClose={handleClose}
		/>
	</React.Fragment>)

}

export const useConfirmation = () => React.useContext(ConfirmationServiceContext)
