import React from 'react'
import { createStyles, makeStyles, Typography } from "@material-ui/core"
import Avatar from "@material-ui/core/Avatar"
import { User } from "../../api"
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// import { Link } from 'react-router-dom';

interface ProfileToolbarElementProps {
    user: User,
    onLogout: () => void
}

const useStyles = makeStyles((theme) => createStyles({
    wrapper: {
        display: "flex",
        alignItems: "center"

    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText
    },
    nameBox: {
        color: theme.palette.primary.contrastText,
        marginLeft: theme.spacing(1),
        textTransform: "none"
    }
}
))

const ProfileToolbarElement = (props: ProfileToolbarElementProps) => {
    const { user, onLogout } = props
    const classes = useStyles()


    const [anchorEl, setAnchorEl] = React.useState<Element>();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(undefined);
    };

    const handleLogout = () => {
        handleClose()
        onLogout()
    }

    return <React.Fragment><Button className={classes.wrapper} onClick={handleClick}>
        <Avatar className={classes.avatar}>
            {user.firstName?.slice(0, 1)?.toUpperCase() || ""}{user.lastName?.slice(0, 1)?.toUpperCase() || ""}
        </Avatar>
        <Typography variant="h6" className={classes.nameBox}>{[user.firstName, user.lastName].map(a => a || "").filter(a => a.length > 0).join(" ")}</Typography>

    </Button>
        <Menu
            anchorEl={anchorEl}
            keepMounted
            open={!!anchorEl}
            onClose={handleClose}
        >
            <MenuItem onClick={handleLogout}>Abmelden</MenuItem>
            {/* <MenuItem onClick={handleClose} component={Link} to="/changePassword">Passwort Ändern</MenuItem> */}
        </Menu>
    </React.Fragment>
}

export default ProfileToolbarElement