import { useState, useEffect, useMemo, useCallback } from "react"
import Box from "@material-ui/core/Box"
import InputBase from '@material-ui/core/InputBase';
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';

import PersonAddIcon from '@material-ui/icons/PersonAdd';

import { makeStyles } from '@material-ui/core/styles';
import { useAuth, useLoading, useShowMessage } from "../../context"
import { ProfileApi, ProfileListEntry } from "../../api"
import { AutoSizer, List, ListRowRenderer } from "react-virtualized"
import { useHistory } from "react-router";

const filterProfile = (searchQuery: string) => (profile: ProfileListEntry): boolean => {
    const regex = new RegExp(searchQuery, "i")
    return regex.test((profile.firstName || "") + profile.lastName + profile.userId)
}

const LIST_ITEM_HEIGHT = 60

const useStyles = makeStyles({
    listItem: {
        display: "flex",
        flexDirection: "row",
        marginBottom: 1,
        marginLeft: 1,
        height: LIST_ITEM_HEIGHT
    },
    inactive: {
        color: "gray"
    }
})

const profileSorter = (a: ProfileListEntry, b: ProfileListEntry): number => {
    if (a.lastName < b.lastName)
        return -1
    if (a.lastName > b.lastName)
        return 1

    if ((a.firstName || "") < (b.firstName || ""))
        return -1
    if ((a.firstName || "") > (b.firstName || ""))
        return 1

    if (a.userId < b.userId)
        return -1
    if (a.userId > b.userId)
        return 1
    return 0
}

const getStyle = (roleId: string): React.CSSProperties => {
    const defaultStyle = {
        backgroundColor: "gray",
        color: "white"
    }
    switch (roleId) {
        case "R":
            return {
                ...defaultStyle,
                backgroundColor: "blue",
                color: "white"
            }
        case "A":
            return {
                ...defaultStyle,
                backgroundColor: "red",
                color: "white"
            }
        case "M":
            return {
                ...defaultStyle,
                backgroundColor: "yellow",
                color: "black"
            }
        case "J":
            return {
                ...defaultStyle,
                backgroundColor: "green",
                color: "black"
            }
        case "T":
            return {
                ...defaultStyle,
                backgroundColor: "pink",
                color: "black"
            }
        case "V":
            return {
                ...defaultStyle,
                backgroundColor: "purple",
                color: "white"
            }
        default:
            return defaultStyle
    }
}

export const UserList = () => {
    const [profiles, setProfiles] = useState<ProfileListEntry[]>([])

    const [searchQuery, setSearchQuery] = useState("")
    const [showOnlyActive, setShowOnlyActive] = useState(true)
    const showMessage = useShowMessage()
    const { indicateLoading } = useLoading()
    const { user } = useAuth()
    const history = useHistory()

    const styles = useStyles()

    const openUser = (userId: string) => {
        history.push(history.location.pathname + "/" + userId)
    }

    const filteredProfiles = useMemo(() =>
        profiles
            .filter(filterProfile(searchQuery))
            .filter(p => !showOnlyActive || p.active)
            .sort(profileSorter),
        [profiles, searchQuery, showOnlyActive])

    useEffect(() => {
        if (user)
            ProfileApi.listProfiles(user).then(res => setProfiles(res))
                .catch(_rej => showMessage({
                    message: "Fehler beim Laden",
                    severity: "error"
                }))
                .finally(indicateLoading())
    }, [setProfiles, indicateLoading, user, showMessage])

    const addPerson = useCallback(
        () => {
            history.push(history.location.pathname + "/create")
        },
        [history],
    )

    if (!user?.role?.appPermissions?.find(ap => ap === "PROFILE_ADMIN"))
        return <div>Nicht Berechtigt</div>

    const renderRow: ListRowRenderer = ({ index, key, style }) => {
        const profile = filteredProfiles[index]
        return <Box key={key}
            className={styles.listItem}
            style={style} onClick={() => openUser(profile.userId)}>
            <Box marginRight={2} padding={1}>
                <Avatar style={getStyle(profile.roleId)}>{profile.roleId}</Avatar>
            </Box>
            <Box className={profile.active ? "" : styles.inactive}>
                <Typography variant="h6">{[profile.firstName, profile.lastName].filter(a => !!a).join(" ")}</Typography>
                <Typography variant="subtitle1">{profile.userId}</Typography>
            </Box>
        </Box>
    }

    return <Box height={"90%"}>
        <Box padding={3} display="flex" flexDirection="row">
            <Box flex={1}>
                <InputBase
                    fullWidth
                    color="primary"
                    placeholder="Suchen..."
                    inputProps={{ 'aria-label': 'search' }}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    value={searchQuery}
                /></Box>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={showOnlyActive}
                        onChange={(e) => setShowOnlyActive(e.target.checked)}
                        name="onlyActive"
                        color="secondary"
                    />
                }
                label="Nur Aktive"
            />
            <IconButton
                onClick={addPerson}>
                <PersonAddIcon />
            </IconButton>
        </Box>
        <AutoSizer>
            {({ width, height }) => <List
                ref="List"
                height={height}
                width={width}
                overscanRowCount={15}
                rowCount={filteredProfiles.length}
                rowHeight={LIST_ITEM_HEIGHT}
                rowRenderer={renderRow}
            />}
        </AutoSizer>
    </Box>
}
