import React from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"

type ConfirmationDialogProps = {
	title?: string,
	message: string,
	open: boolean,
	confirmText: string,
	cancelText: string,
	onSubmit: () => void,
	onClose: () => void
}

const ConfirmationDialog = ({ title, message, open, onSubmit, onClose, confirmText, cancelText }: ConfirmationDialogProps) => {
	return <Dialog
		open={open}
		onClose={onClose}
	>
		{title && <DialogTitle>{title}</DialogTitle>}
		<DialogContent>
			<DialogContentText>{message}</DialogContentText>
		</DialogContent>
		<DialogActions>
			<Button onClick={onClose} color="primary">
				{cancelText}
			</Button>
			<Button onClick={onSubmit} color="primary" autoFocus>
				{confirmText}
			</Button>
		</DialogActions>
	</Dialog>
}

ConfirmationDialog.defaultProps = {
	confirmText: "Ja",
	cancelText: "Abbrechen"
}

export default React.memo(ConfirmationDialog)