class DateRange {
    readonly from: Date
    readonly to: Date

    constructor(from: Date, to: Date) {
        if (from.getTime() > to.getTime())
            throw new Error("'From' must be before 'to'")
        this.from = from
        this.to = to
    }

    public equals(range: DateRange): boolean {
        return range.from.getTime() === this.from.getTime()
            && range.to.getTime() === this.to.getTime()
    }

    public intersects(range: DateRange): boolean {
        return range.from <= this.to && range.to >= this.from
    }

    public contains(date: Date): boolean {
        return date >= this.from && date <= this.to
    }

    public covers(range: DateRange): boolean {
        return this.from <= range.from && this.to >= range.to
    }

    public join(range: DateRange): DateRange {
        if (!this.intersects(range))
            throw new Error("Can't join Ranges because they do not overlap")

        return new DateRange(
            new Date(Math.min(range.from.getTime(), this.from.getTime())),
            new Date(Math.max(range.to.getTime(), this.to.getTime()))
        )
    }

}

export class TimeLine {
    private readonly ranges: DateRange[]

    private constructor(ranges: DateRange[]) {
        this.ranges = ranges
    }

    public static empty(): TimeLine {
        return new TimeLine([])
    }

    public static create(from: Date, to: Date): TimeLine {
        return new TimeLine([new DateRange(from, to)])
    }

    public covers(from: Date, to: Date): boolean {
        const targetRange = new DateRange(from, to)
        return !!this.ranges.find(range => range.covers(targetRange))
    }

    public concat(from: Date, to: Date): TimeLine {
        const newRange = new DateRange(from, to)

        const intersectingRanges = this.ranges.filter(range => range.intersects(newRange))
        const mergedRange = intersectingRanges
            .reduce((merged, current) => merged.join(current), newRange)

        return new TimeLine(this.ranges
            .filter(range => !intersectingRanges.find(ir => ir.equals(range)))
            .concat(mergedRange)
        )
    }

    public remove(from: Date, to: Date): TimeLine {
        const rangeToDelete = new DateRange(from, to)
        const intersectingRanges = this.ranges.filter(range => range.intersects(rangeToDelete))

        const newRanges = intersectingRanges.reduce<DateRange[]>((nr, curr) => {
            // exact match or to delete contains item completely
            if (curr.from.getTime() >= rangeToDelete.from.getTime() && curr.to.getTime() <= rangeToDelete.to.getTime())
                return nr
            // to delete is contained
            if (curr.from.getTime() <= rangeToDelete.from.getTime() && curr.to.getTime() >= rangeToDelete.to.getTime())
                return nr.concat(
                    [new DateRange(curr.from, rangeToDelete.from), new DateRange(rangeToDelete.to, curr.to)]
                )

            // to delete period overlaps with to
            if (curr.to.getTime() > rangeToDelete.from.getTime() && curr.from.getTime() < rangeToDelete.from.getTime())
                return nr.concat(new DateRange(curr.from, rangeToDelete.from))
            // to delete period overlaps with from
            if (curr.from.getTime() < rangeToDelete.to.getTime() && curr.from.getTime() < rangeToDelete.to.getTime())
                return nr.concat(new DateRange(rangeToDelete.to, curr.to))

            // should never happen
            return nr
        }, [])

        return new TimeLine(this.ranges
            .filter(range => !intersectingRanges.find(ir => ir.equals(range)))
            .concat(newRanges)
        )
    }
}