import React, { CSSProperties } from "react"
import { createStyles, makeStyles } from "@material-ui/core/styles"
import { isSameReservationInfo, ReservationInfo, User } from "../../api"
import { useResInfoSelection } from "../../context"

type CourtHourProps = {
	reservationInfo: ReservationInfo,
	onClick: () => void,
	user?: User,
	className?: string
	style?: CSSProperties,
	verbose?: boolean
}


const useStyles = makeStyles((theme) => createStyles({
	wrapper: ({ reservationInfo, user }: CourtHourProps) => {
		const { reservation, reservationError, cancellationError } = reservationInfo

		const pallette = (!!reservation && (reservation.reservedBy.id === user?.id || reservation.players.find(pl => pl.id === user?.id)) ? "primary" : "secondary")

		const colorVariant = reservation ?
			reservation.reservedBy.roleId === 'X' ?
				theme.palette.error.main :
				theme.palette[pallette][!cancellationError ? "main" : "dark"] :
			(user && !!reservationError && !!cancellationError) ? theme.palette.text.disabled : undefined

		return {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			backgroundColor: colorVariant,
			transition: "background-color 0.3s linear",
			padding: 0,
			color: colorVariant ? theme.palette.getContrastText(colorVariant) : undefined,
			cursor: "pointer",
			borderRadius: theme.shape.borderRadius,
			border: !colorVariant ? `1px solid ${theme.palette.type === "light" ? "rgba(0, 0, 0, 0.23)" : "rgba(255, 255, 255, 0.23)"}` : undefined
		}
	},
	selected: {
		backgroundColor: theme.palette.text.disabled + " !important"
	},
	resText: {
		fontFamily: theme.typography.fontFamily,
		fontSize: theme.typography.fontSize,
		textAlign: "center"
	}
}
))

const CourtHour = (props: CourtHourProps) => {
	const classes = useStyles(props)
	const { reservationInfo, onClick, className, style, verbose } = props

	const { isSelected } = useResInfoSelection()
	const selected = isSelected(props.reservationInfo)

	const getText = () => {
		const reservation = reservationInfo.reservation
		if (!reservation)
			return ""
		if (!verbose)
			return reservation.reservedBy.roleId

		if (reservation.players.length > 0)
			return reservation.players.map(pl => pl.lastName).join(" / ")

		//trainer
		if (reservation.reservedBy.roleId === "T") {
			let text = `TR - ${reservation.reservedBy.lastName}`
			if (reservation.description)
				text += `: ${reservation.description}`
			return text
		}

		// Admin reservierung
		let text = reservation.reservedBy.roleId
		if (reservation.description)
			text += `: ${reservation.description}`
		else if (reservation.reservedBy.roleId === "X")
			text = `Gesperrt`

		return text
	}

	return <div className={[classes.wrapper,

	className || "", selected ? classes.selected : ""].join(" ")}
		style={{ ...style }} onClick={onClick}>
		<span className={classes.resText}>
			{getText()}
		</span>
	</div>

}

export default React.memo(CourtHour, (prevProps, nextProps) =>
	prevProps.user?.id === nextProps.user?.id
	&& isSameReservationInfo(prevProps.reservationInfo, nextProps.reservationInfo)
	&& nextProps.onClick === prevProps.onClick
	&& nextProps.style === prevProps.style
	&& nextProps.className === prevProps.className
	&& nextProps.verbose === prevProps.verbose
)