import React, { useEffect, useState, useRef } from "react"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { AuthError } from "../../api"
import { useLoading } from "../../context"

type LoginFormProps = {
	login: (username: string, password: string) => Promise<void>
}

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: "flex",
		flexDirection: "column",
		alignItems: "center"
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	}
}))

const LoginForm = (props: LoginFormProps) => {
	let _isMounted = useRef(false)
	const [username, setUsername] = useState("")
	const [password, setPassword] = useState("")
	const [errorMessage, setErrorMessage] = useState<string>()
	const [working, setWorking] = useState(false)
	const { indicateLoading } = useLoading()
	const { login } = props

	const classes = useStyles(props)

	useEffect(() => {
		_isMounted.current = true
		return () => {
			_isMounted.current = false
		}
	})

	const doLogin = () => {
		setWorking(true)
		login(username, password)
			.catch((err) => {
				if (err instanceof AuthError)
					setErrorMessage("Benutzername oder Passwort falsch")
				else
					setErrorMessage("Fehler bei der Anmeldung")
			})
			.finally(indicateLoading())
			.finally(() => {
				if (_isMounted.current) setWorking(false)
			})
	}

	return <Container component="main" maxWidth="xs">
		<div className={classes.paper}>
			<Typography component="h1" variant="h5">
				Anmeldung
				</Typography>
			<form className={classes.form} noValidate onSubmit={(e) => {
				e.preventDefault()
				doLogin()
			}}>
				<TextField
					variant="outlined"
					margin="normal"
					required
					fullWidth
					id="username"
					label="Benutzername"
					name="username"
					autoFocus
					value={username}
					onChange={(e) => setUsername(e.target.value)}
				/>
				<TextField
					variant="outlined"
					margin="normal"
					required
					fullWidth
					name="password"
					label="Passwort"
					type="password"
					id="password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>
				<Typography variant="body1" color="error"
					hidden={!errorMessage}>{errorMessage}</Typography>
				<Button
					type="submit"
					fullWidth
					variant="contained"
					color="primary"
					disabled={working || !username}
					className={classes.submit}>
					{!working ? "Anmelden" : "Melde an ..."}
				</Button>
			</form>
		</div>
	</Container>
}

export default LoginForm