import React from "react"
import moment from 'moment'
import List from "@material-ui/core/List"
import MuiListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import TextField from '@material-ui/core/TextField'
import Divider from '@material-ui/core/Divider'

import EventIcon from '@material-ui/icons/Event'
import TennisIcon from '@material-ui/icons/SportsTennis'
import DescriptionIcon from '@material-ui/icons/Description'
import PersonIcon from '@material-ui/icons/Person'
import ListItem from './ListItem'
import formatName from "../../../tools/formatName"
import useReservationRules from "../../../../../hooks/useReservationRules"
import { useAuth } from "../../../../../context"
import { ReservationDialogProps } from "../../ReservationDialogProps"


const ReservationContent = (props: ReservationDialogProps & { onRequestSelectPlayer2: () => void }) => {
	const { reservationInfo, player2, onRequestSelectPlayer2, onDescriptionChanged, description } = props
	const { hour, courtId, reservation } = reservationInfo
	const { user } = useAuth()
	const { requiresPlayerSelection, canEnterDescription } = useReservationRules()

	return <List>
		<ListItem
			title="Stunde" value={moment(hour).format("HH:mm, dddd D. MMMM")}
			icon={<EventIcon />} />
		<ListItem
			title="Platz" value={`Platz ${courtId}`}
			icon={<TennisIcon />} divider />
		{reservation && reservation.players.length > 0 &&
			reservation?.players?.map((player, index) =>
				<ListItem key={player.id} title={`Spieler ${index + 1}`} divider
					value={formatName(player)} icon={<PersonIcon />} />
			)}
		{reservation && reservation.players.length === 0 &&
				<ListItem key={reservation.reservedBy.id} title="Reserviert von" divider
					value={formatName(reservation.reservedBy)} icon={<PersonIcon />} />}
		{!reservation && requiresPlayerSelection && user &&
			<React.Fragment>
				<ListItem title={`Spieler 1`} divider
					value={formatName(user)} icon={<PersonIcon />} />
				<ListItem title={`Spieler 2`} divider
					value={player2 ? formatName(player2) : "Bitte Auswählen"} icon={<PersonIcon />}
					highlight={!player2} onClick={onRequestSelectPlayer2}
				/>
			</React.Fragment>}
		{/* {(((reservation?.description?.length || 0) > 0) || (!reservation && canEnterDescription)) &&
                <DialogContentArea accent={<DescriptionIcon />}>
                    {!reservation && onDescriptionChanged ?
                        <TextField placeholder="Bezeichnung" multiline={true} value={description} onChange={(e) => onDescriptionChanged(e.target.value)} /> :
                        <Typography variant="body1">{reservation?.description}</Typography>}
                </DialogContentArea>} */}
		{(reservation && (reservation?.description?.length || 0) > 0) &&
			<ListItem title="Bezeichnung" value={reservation?.description || ""}
				icon={<DescriptionIcon />} divider />}
		{!reservation && canEnterDescription && onDescriptionChanged &&
			<React.Fragment>
				<Divider />
				<MuiListItem>
					<ListItemIcon>
						<DescriptionIcon />
					</ListItemIcon>
					<TextField
						fullWidth={true}
						placeholder="Bezeichnung"
						value={description || ""}
						onChange={(e) => onDescriptionChanged(e.target.value)}
					/>
				</MuiListItem>
			</React.Fragment>
		}

	</List>
}

export default ReservationContent
