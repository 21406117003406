import { useHistory } from "react-router-dom"
import { AuthError } from "../api"
import { ReservationError } from "../api"
import { useAuth, useShowMessage } from "../context"
import { useCallback } from "react"

const useHandleReservationError = () => {
	const showMessage = useShowMessage()
	const { logout } = useAuth()
	const history = useHistory()

	return useCallback((err: Error) => {
		if (err instanceof AuthError) {
			logout()
			showMessage({
				message: "Bitte melde dich neu an",
				severity: "error"
			})
			history.push("/login")
		} else if (err instanceof ReservationError) {
			showMessage({ message: err.message, severity: "error" })
		} else
			showMessage({
				message: "Leider ist ein Fehler aufgetreten",
				severity: "error"
			})
	}, [showMessage, logout, history])
}

export default useHandleReservationError