const getReservationTitleByRole = (roleId: string | undefined) => {
	if (!roleId) return "Neue Reservierung"
	switch (roleId) {
		case "M":
			return "Meisterschaft"
		case "V":
			return "Veranstaltung"
		case "T":
			return "Trainerstunde"
		case "G":
			return "Gast"
		case "A":
			return "Admin"
		case "X":
			return "Sperre"
		default:
			return "Reservierung"
	}
}

export default getReservationTitleByRole