import React, { createContext,  } from "react"
import { AuthApi, User } from "../api"
import usePersistedUser from "../hooks/usePersistedUser"

export type AuthContextValue = {
	user?: User,
	login: (username: string, password: string) => Promise<void>,
	logout: () => void
}

const AuthServiceContext = createContext<AuthContextValue>({
	login: () => Promise.resolve(),
	logout: () => {
	}
})

export const AuthServiceProvider = ({ children }: { children: React.ReactNode }) => {
	const [user, setUser] = usePersistedUser()

	const logout = () => {
		setUser(undefined)
	}

	const login = (username: string, password: string) =>
		AuthApi.login(username, password)
			.then(setUser)

	return (<React.Fragment>
		<AuthServiceContext.Provider
			value={{ user, login, logout }}
			children={children}
		/>
	</React.Fragment>)

}

export const useAuth = () => React.useContext(AuthServiceContext)
