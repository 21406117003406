import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    contentArea: {
        display: "flex",
        flexDirection: "row",
        paddingTop: theme.spacing(2)
    },
    contentAccent: {
        paddingRight: theme.spacing(2),
        width: 60
    },
    contentItems: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        flex: 1
    },
    contentItem: {
        flexGrow: 1,
        flexShrink: 0,
        maxWidth: "100%",
        paddingRight: theme.spacing(2)
    }
}))

interface DialogContentAreaProps {
    accent?: React.ReactNode,
    children: React.ReactNode
}

export const DialogContentArea = (props: DialogContentAreaProps) => {
    const classes = useStyles(props)

    const { accent, children } = props

    return <div className={classes.contentArea}>
        <div className={classes.contentAccent}>
            {accent}
        </div>
        <div className={classes.contentItems}>
            {React.Children.map(children, child =>
                React.isValidElement(child) ?
                    React.cloneElement(child, { className: clsx(child.props.className, classes.contentItem) }) :
                    child)}
        </div>
    </div>
}