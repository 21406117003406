import React from "react"
import IconButton from "@material-ui/core/IconButton"
import Input from "@material-ui/core/Input"
import CloseIcon from "@material-ui/icons/Close"
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import Typography from "@material-ui/core/Typography"
import MuiToolbar from "@material-ui/core/Toolbar"
import AppBar from "@material-ui/core/AppBar"
import Box from "@material-ui/core/Box"
import makeStyles from "@material-ui/core/styles/makeStyles"
import createStyles from "@material-ui/core/styles/createStyles"

type ToolbarProps = {
	navButtonPressed: () => void,
	text: string,
	large?: boolean,
	children?: React.ReactNode,
	closeIcon?: "close" | "chevron",
	textChange?: {
		onTextChanged: (newText: string) => void,
		placeholder: string
	}
}

const useStyles = makeStyles((theme) =>
	createStyles({
		toolBar: {},
		title: {
			flexGrow: 1,
			marginRight: theme.spacing(3)
		},
		toolBarActions: {}
	})
)
const Toolbar = (props: ToolbarProps) => {
	const classes = useStyles(props)

	const { navButtonPressed, text, children, closeIcon = "close", textChange } = props

	return <AppBar position="static" color="transparent">
		<MuiToolbar className={classes.toolBar}>
			<IconButton edge="start" color="inherit"
				onClick={navButtonPressed}
				aria-label="close">
				{closeIcon === "chevron" ? <ChevronLeft /> : <CloseIcon />}
			</IconButton>
			{!textChange ? <Typography variant="h6"
				className={classes.title}
				noWrap>{text}</Typography> :
				<Input
					onChange={(e) => textChange.onTextChanged(e.target.value)}
					value={text}
					className={classes.title} autoFocus
					placeholder={textChange.placeholder} />}
			<Box className={classes.toolBarActions}>
				{children}
			</Box>
		</MuiToolbar>
	</AppBar>
}

export default Toolbar