import React from "react"
import { ThemeProvider } from "@material-ui/core/styles"
import theme from "./assets/Theme"
import CssBaseline from "@material-ui/core/CssBaseline"
import { BrowserRouter as Router } from "react-router-dom"

import MainPage from "./components/MainPage"
import {
	AuthServiceProvider,
	ConfirmationServiceProvider,
	MessageServiceProvider,
	LoadingContextProvider,
	SocketProvider,
	ClubHouseContextProvider
} from "./context"
import { FullScreenLoadingOverlay } from './components/LoadingIndicator'
import { SingleInputDialogProvider } from "./components/SingleInputDialog"

const App = () =>
	<AuthServiceProvider>
		<SocketProvider>
			<ThemeProvider theme={theme()}>
				<LoadingContextProvider loadingComponent={FullScreenLoadingOverlay}>
					<MessageServiceProvider>
						<ConfirmationServiceProvider>
							<ClubHouseContextProvider>
								<SingleInputDialogProvider>
									<CssBaseline />
									<Router>
										<MainPage />
									</Router>
								</SingleInputDialogProvider>

							</ClubHouseContextProvider>
						</ConfirmationServiceProvider>
					</MessageServiceProvider>
				</LoadingContextProvider>
			</ThemeProvider>
		</SocketProvider>
	</AuthServiceProvider>

export default App