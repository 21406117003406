import React from "react"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import { createStyles, makeStyles } from "@material-ui/core/styles"
import { Court, ReservationInfo, User } from "../../api"
import { range } from "../../tools"
import CourtHour from "./CourtHour"

type CourtsViewProps = {
	forDate: Date,
	courts: Court[],
	reservationInfo: ReservationInfo[],
	onInfoClick: (info: ReservationInfo) => void,
	showHours: boolean,
	user?: User,
	verbose?: boolean
}

const times = range(7, 20)

const useStyles = makeStyles((theme) => createStyles({
	gridContainer: ({ courts, showHours }: CourtsViewProps) => ({
		display: "grid",
		gridTemplateColumns: `${showHours ? "[time] 50px " : ""}${courts.map(court => `[court${court.id}] minmax(${theme.spacing(4)}px, 1fr)`).join(" ")}`,
		gridTemplateRows: `[courts] 1fr ${times.map(hour => `[hour${hour}] minmax(${theme.spacing(3)}px, 1fr)`).join(" ")}`,
		justifyItems: "stretch",
		alignItems: "stretch",
		flex: 1
	}),
	hour: {
		gridColumnStart: "time",
		placeSelf: "center",
	},
	courtHeader: {
		placeSelf: "center",
		gridRowStart: "courts"
	},
	cell: {
		minWidth: 0,
		margin: theme.spacing(1 / 3)
	}
}
))

const CourtsView = (props: CourtsViewProps) => {
	const {
		forDate, courts, reservationInfo, onInfoClick, showHours, user, verbose
	} = props
	const classes = useStyles(props)

	const relevantInfo = reservationInfo.filter(ri =>
		ri.hour.getFullYear() === forDate.getFullYear()
		&& ri.hour.getMonth() === forDate.getMonth()
		&& ri.hour.getDate() === forDate.getDate())

	return <Box className={classes.gridContainer}>

		{showHours && <p className={classes.hour} />}

		{courts.map(court => <Typography
			key={`court${court.id}`}
			style={{ gridColumnStart: `court${court.id}` }}
			variant="body1"
			className={classes.courtHeader}>{court.id}</Typography>)}

		{showHours && times.map(hour => <Typography
			key={hour} variant="body2"
			style={{ gridRowStart: `hour${hour}` }}
			className={classes.hour}>{`${hour}:00`}</Typography>)}

		{relevantInfo.map(info => {
			const hour = info.hour.getHours()
			return <CourtHour
				key={`${hour}-court${info.courtId}`}
				reservationInfo={info}
				user={user}
				onClick={() => onInfoClick(info)}
				className={classes.cell}
				verbose={verbose}
				style={{
					gridArea: `hour${hour} / court${info.courtId} / hour${hour} / court${info.courtId}`
				}}
			/>
		})}
	</Box>
}

CourtsView.defaultProps = {
	showHours: true
}

export default React.memo(CourtsView)