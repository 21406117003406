import { Court, ReservationInfo } from "../api"

export const getHoursInRange = (from: Date, to: Date, inclusiveEnd?: boolean): Date[] => {
    const fromHour = parseInt(process.env.REACT_APP_FROM_HOUR || "7")
    const toHour = parseInt(process.env.REACT_APP_TO_HOUR || "20")

    const hours: Date[] = []

    for (let day = from; (day.getTime() < to.getTime() && !inclusiveEnd) || ((day.getTime() <= to.getTime() && inclusiveEnd)); day = new Date(day.getTime() + 60 * 60 * 1000)) {
        if (day.getHours() < fromHour || day.getHours() > toHour)
            continue

        hours.push(day)
    }

    return hours
}

export const getSelectionSpan = (res1: ReservationInfo, res2: ReservationInfo, allCourts: Court[]): ReservationInfo[] => {

    const involvedCourts: Court[] = [res1, res2].map(res => allCourts.find(c => c.id === res.courtId) as Court)
    const courtIds = involvedCourts.map(c => allCourts.indexOf(c))

    const coveredCourts = allCourts.filter((x, idx) => idx <= Math.max(...courtIds) && idx >= Math.min(...courtIds))


    const from = Math.min(res1.hour.getTime(), res2.hour.getTime())
    const to = Math.max(res1.hour.getTime(), res2.hour.getTime())
    const info: ReservationInfo[] = []

    getHoursInRange(new Date(from), new Date(to), true).forEach(day => {
        coveredCourts.forEach(court => {
            info.push({
                courtId: court.id,
                hour: new Date(day),
                reservationError: undefined,
                cancellationError: undefined
            })

        })
    })

    return info
}