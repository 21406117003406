
import React, { useState, useCallback, useEffect } from "react"
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import TextField from '@material-ui/core/TextField'
import Checkbox from "@material-ui/core/Checkbox"
import Box from "@material-ui/core/Box"
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';

import { ProfileApi, Profile } from "../../../api"
import { useAuth, useConfirmation, useLoading, useShowMessage } from "../../../context"

export type UserProps = {
    userId: string
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2)
    },
    control: {
        padding: theme.spacing(2),
    },
}));

const DEFAULT_PROFILE: Profile =
{
    userId: "",
    firstName: "",
    password: "",
    lastName: "",
    active: true,
    birthDate: new Date(1980, 0, 1),
    changePassword: false,
    city: "",
    mobile: "",
    salutation: "",
    sex: "M",
    street: "",
    sttvLink: "",
    title: "",
    sendEmail: true,
    sendPost: true,
    sendSms: true,
    roleId: "R"
}

const required: Array<keyof Profile> = ["userId", "lastName", "roleId"]

const FormControlGridItem = ({ children }: { children: React.ReactNode }) =>
    <Grid item xs={12} md={6}>
        {children}
    </Grid>

export const User = ({ userId }: UserProps) => {
    const isCreate = (userId === "create" || userId === "-1")

    const [profile, setProfile] = useState<Partial<Profile>>({
        ...DEFAULT_PROFILE,
        changePassword: isCreate ? true : DEFAULT_PROFILE.changePassword
    })

    const { user } = useAuth()
    const { indicateLoading } = useLoading()
    const classes = useStyles()
    const showConfirmation = useConfirmation()
    const history = useHistory()
    const showMessage = useShowMessage()


    useEffect(() => {
        if (!user || !userId || isCreate)
            return

        ProfileApi.getProfile(user, userId)
            .then((profile) => setProfile(profile || DEFAULT_PROFILE))
            .finally(indicateLoading())
    }, [user, userId, setProfile, indicateLoading, isCreate])


    const handleInputChange = useCallback(
        (fieldName: keyof Profile): React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> => (e) => {
            const newValue = e.target.value
            setProfile((p) => ({ ...p, [fieldName]: newValue }))
        },
        [setProfile],
    )

    const handleCheckChange = useCallback(
        (fieldName: keyof Profile) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
            const newValue = checked
            setProfile((p) => ({ ...p, [fieldName]: newValue }))
        },
        [setProfile],
    )

    const handleSave = useCallback(
        () => {
            showConfirmation({ message: "Wirklich Speichern?" })
                .then(() => {
                    if (!user) return
                    if (!isCreate)
                        return ProfileApi.updateProfile(user, profile)

                    return ProfileApi.createProfile(user, profile as Profile)
                })
                .then(() => history.push("/users"))
                .then(() => showMessage({ severity: "success", message: "Gespeichert" }))
                .catch((error) => {
                    showMessage({ message: error?.message || "Fehler beim speichern", severity: "error" })
                })
                .finally(indicateLoading())
        },
        [showConfirmation, showMessage, profile, user, indicateLoading, history, isCreate],
    )
    const handleDelete = useCallback(
        () => {
            showConfirmation({ title: "Wirklich löschen?", message: "Löschen kann nicht rückgängig gemacht werden" })
                .then(() => {
                    if (!user || !profile.userId) return
                    return ProfileApi.deleteProfile(user, profile.userId)
                })
                .then(() => history.push("/users"))
                .then(() => showMessage({ severity: "success", message: "Gelöscht" }))
                .catch((error) => {
                    showMessage({ message: error?.message || "Fehler beim speichern", severity: "error" })
                })
                .finally(indicateLoading())
        },
        [showConfirmation, user, profile, history, showMessage, indicateLoading],
    )

    const mandatoryMissing = required.reduce((missing, field) => missing || !profile[field], false)

    if (!profile.userId && !isCreate) return <Typography variant="body1">Benutzer nicht gefunden</Typography>

    return <form>
        <Grid container className={classes.root} spacing={2}>

            <FormControlGridItem>
                <TextField fullWidth required={isCreate} label={"Benutzername"}
                    value={profile.userId} onChange={handleInputChange("userId")}
                    disabled={!isCreate}
                />
            </FormControlGridItem>
            <FormControlGridItem>
                <Box flex={1} display="flex" flexDirection="row">
                    <TextField fullWidth label={"Passwort"}
                        type="password"
                        value={profile.password} onChange={handleInputChange("password")}
                    />
                    <FormControlLabel
                        control={<Checkbox color="secondary" disabled={isCreate} checked={profile.changePassword} onChange={handleCheckChange("changePassword")} />}
                        label="Ändern"
                    />

                </Box>
            </FormControlGridItem>
            <FormControlGridItem>
                <FormControl fullWidth >
                    <InputLabel>Rolle</InputLabel>
                    <Select
                        value={profile.roleId}
                        onChange={(e) => setProfile(p => ({ ...p, roleId: e?.target?.value as string || "R" }))}
                    >
                        <MenuItem value={"R"}>Mitglied</MenuItem>
                        <MenuItem value={"J"}>Jugend</MenuItem>
                        <MenuItem value={"T"}>Trainer</MenuItem>
                        <MenuItem value={"A"}>Reservierungsadmin</MenuItem>
                        <MenuItem value={"M"}>Meisterschaftsadmin</MenuItem>
                        <MenuItem value={"V"}>Veranstaltungsadmin</MenuItem>
                        <MenuItem value={"X"}>Platzwart</MenuItem>
                    </Select>
                </FormControl>
            </FormControlGridItem>

            <FormControlGridItem>
                <TextField fullWidth label={"Vorname"}
                    value={profile.firstName} onChange={handleInputChange("firstName")} />
            </FormControlGridItem>
            <FormControlGridItem>
                <TextField fullWidth required label={"Nachname"}
                    value={profile.lastName} onChange={handleInputChange("lastName")} />
            </FormControlGridItem>

            <FormControlGridItem>
                <FormControlLabel
                    control={<Checkbox color="secondary" checked={profile.active} onChange={handleCheckChange("active")} />}
                    label="Aktiv"
                />
            </FormControlGridItem>

            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={(!isCreate && !!profile.userId) ? 6 : 12}>
                        <Button fullWidth variant="outlined" disabled={mandatoryMissing} color="primary" onClick={handleSave}>Speichern</Button>
                    </Grid>
                    {!isCreate && !!profile.userId && <Grid item xs={6}>
                        <Button fullWidth variant="outlined" color="secondary" onClick={handleDelete}>Löschen</Button>
                    </Grid>}
                </Grid>
            </Grid>
        </Grid>
    </form>

}
