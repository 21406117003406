import React, { createContext, useRef } from "react"
import io from "socket.io-client"

const SocketContext = createContext<SocketIOClient.Socket | undefined>(undefined)

export const SocketProvider = ({ children }: { children: React.ReactNode }) => {

	const socket = useRef<SocketIOClient.Socket | undefined>(undefined)

	let url = new URL(process.env.REACT_APP_API_BASE_URL || "").origin

	if (socket.current?.connected)
		socket.current.close()
	socket.current = io(url)

	return <React.Fragment>
		<SocketContext.Provider
			value={socket.current}
			children={children}
		/>
	</React.Fragment>
}

export const useSocket = () => React.useContext(SocketContext)
