import { useMemo, useState } from "react"
import { User } from "../api"

const TOKEN_STORAGE_KEY = "authToken"

type SetUserAction = {
	(user: User | string | undefined): void
}

const usePersistedUser = (): [User | undefined, SetUserAction] => {
	const [authToken, setAuthToken] = useState<string | null>(localStorage.getItem(TOKEN_STORAGE_KEY))

	const user = useMemo(() => {
		if (!authToken) return undefined
		try {
			return new User(authToken)
		}
		catch (e) {
			console.error(e)
			setAuthToken(null)
		}
	}, [authToken])

	const setUser: SetUserAction = (user) => {
		const token: string =
			!user ? "" :
				typeof user === "string" ?
					user :
					user.token

		localStorage.setItem(TOKEN_STORAGE_KEY, token)

		setAuthToken(token)
	}


	return [user, setUser]
}

export default usePersistedUser