import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import createStyles from '@material-ui/core/styles/createStyles'
import CircularProgress from '@material-ui/core/CircularProgress';
import { LoadingComponentProps } from '../../context'

const useStyles = makeStyles((theme) => createStyles({
    loadingOverlay: {
        zIndex: 9999,
        opacity: 0.9,
        backgroundColor: theme.palette.background.default,
        flex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
}))


export const FullScreenLoadingOverlay = ({ children, loadingCount }: LoadingComponentProps) => {
    const classes = useStyles()

    return <React.Fragment>
        {children}
        {loadingCount > 0 ? <div className={classes.loadingOverlay}><CircularProgress /></div> : null}
    </React.Fragment>
}
