import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import createStyles from '@material-ui/core/styles/createStyles'
import LinearProgress from '@material-ui/core/LinearProgress';
import { LoadingComponentProps } from '../../context'

const useStyles = makeStyles((theme) => createStyles({
    progress: {
        marginBottom: theme.spacing(1)
    }
}))


export const LoadingBarIndicator = ({ children, loadingCount, className, style }: LoadingComponentProps) => {
    const classes = useStyles()

    return <div className={className} style={style}>
        {loadingCount > 0 ? <LinearProgress color="secondary" className={classes.progress} /> : null}
        {children}
    </div>

}
