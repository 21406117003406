import md5 from "md5"
import { AuthError } from "./AuthError"
import { User } from "./User"
import { Role } from "./Role"

export class AuthApi {
	static async login(username: string, password: string): Promise<string> {
		const url = new URL("./auth/login", process.env.REACT_APP_API_BASE_URL)

		const res = await fetch(url.toString(), {
			method: "POST",
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				username,
				password: md5(password)
			})
		})

		switch (res.status) {
			case 200:
				break
			case 401:
				throw new AuthError()
			default:
				throw new Error("Error logging in")
		}

		const body = await res.json()

		return body.token
	}

	static async getRole(user: User): Promise<Role> {
		const url = new URL("./auth/role", process.env.REACT_APP_API_BASE_URL)
		const res = await fetch(url.toString(), {
			headers: {
				"Authorization": `Bearer ${user.token}`
			}
		})

		return res.json()
	}
}
