import { createStyles, makeStyles } from "@material-ui/core"
import MuiToolBar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import { Link, useLocation } from "react-router-dom"
import { useAuth, useConfirmation } from "../../context"
import ToolbarButton from "../ToolbarButton"
import ProfileToolbarElement from "./ProfileToolbarElement"


const useStyles = makeStyles((theme) => createStyles({
    title: {
        textDecoration: "none",
        color: theme.palette.primary.contrastText,
        flexGrow: 1
    }
}
))

const Toolbar = () => {
    const location = useLocation()
    const { user, logout } = useAuth()

    const confirm = useConfirmation()

    const confirmLogout = () => {
        confirm({ message: "Willst du dich wirklich abmelden?" })
            .then(() => logout())
    }

    const classes = useStyles()

    return <MuiToolBar>
        <Typography variant="h6" className={classes.title}
            component={Link} to="/">
            Platzreservierung
        </Typography>
        {user?.role?.appPermissions?.find(ap => ap === "PROFILE_ADMIN") &&
            <ToolbarButton color="inherit" component={Link}
                to="/users">Benutzer</ToolbarButton>}
        {location.pathname !== "/login" && (user ?
            <ProfileToolbarElement user={user} onLogout={confirmLogout} /> :
            <ToolbarButton color="inherit" component={Link}
                to="/login">Anmelden</ToolbarButton>)}
    </MuiToolBar>
}


export default Toolbar