import { Role } from "./Role"

export type DecodedToken = {
	sub: string,
	role: Role,
	firstName?: string,
	lastName: string,
	exp: number,
	iat: number
}

export const parseJwt = (token: string): DecodedToken => {
	const base64 =
		token.split(".")[1]
			.replace(/-/g, "+").replace(/_/g, "/")
	const jsonPayload =
		decodeURIComponent(
			atob(base64)
				.split("")
				.map((c) => ("%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)))
				.join(""))

	return JSON.parse(jsonPayload)
}