import { createMuiTheme } from "@material-ui/core/styles"
import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme"
import blue from "@material-ui/core/colors/blue"
import blueGrey from "@material-ui/core/colors/blueGrey"
import amber from "@material-ui/core/colors/amber"

const createTheme = () => {

	const options: ThemeOptions = ({
		palette: {
			type: window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light",
			primary: blue,
			secondary: amber,
			info: blueGrey
		}
	})

	return createMuiTheme(options)
}

export default createTheme