import { Reservation, ReservationInfo } from "../api"
import { useAuth } from "../context"
import { dateRangeParser, RuleEvaluator } from "reservation-rules"
import { useCallback, useMemo } from "react"

interface ReservationRuleOptions {
	reservation: Reservation,
	allReservations: Reservation[]
}
interface BulkReservationRuleOptions {
	reservationsInfo: ReservationInfo[],
	allReservations: Reservation[]
}

interface UseReservationRule {
	canReserve: (options: ReservationRuleOptions) => string | undefined,
	bulkCheckCanReserve: (options: BulkReservationRuleOptions) => void,
	canCancel: (options: ReservationRuleOptions) => string | undefined,
	bulkCheckCanCancel: (options: BulkReservationRuleOptions) => void,
	calcRulePeriodStart: (refDate: Date) => Date,
	calcRulePeriodEnd: (refDate: Date) => Date,
	requiresPlayerSelection: boolean,
	canEnterDescription: boolean,
	mustEnterDescription: boolean
}

const unauthorizedMessage = "Bitte meld dich an!"

const useReservationRules = (): UseReservationRule => {
	const { user } = useAuth()
	const role = user?.role

	const creationEvaluator = useMemo<RuleEvaluator | undefined>(() => role?.reservationCreationRules ? new RuleEvaluator(role.reservationCreationRules) : undefined, [role])
	const cancellationEvaluator = useMemo<RuleEvaluator | undefined>(() => role?.reservationCancellationRules ? new RuleEvaluator(role.reservationCancellationRules) : undefined, [role])

	const canReserve = useCallback((options: ReservationRuleOptions) =>
		creationEvaluator ? creationEvaluator.evaluate({ ...options, userId: user?.id }) : unauthorizedMessage,
		[creationEvaluator, user])

	const canCancel = useCallback((options: ReservationRuleOptions) =>
		cancellationEvaluator ? cancellationEvaluator.evaluate({ ...options, userId: user?.id }) : unauthorizedMessage,
		[cancellationEvaluator, user])

	const bulkCheckCanReserve = useCallback(({ reservationsInfo, allReservations }: BulkReservationRuleOptions) => {
		if (!creationEvaluator)
			reservationsInfo.forEach(ri => ri.reservationError = unauthorizedMessage)
		else {
			creationEvaluator.bulkEvaluate({ reservationsInfo: reservationsInfo, allReservations, userId: user?.id })
			reservationsInfo.forEach(ri => ri.reservationError = ri.violation)
		}
	}, [creationEvaluator, user])

	const bulkCheckCanCancel = useCallback(({ reservationsInfo, allReservations }: BulkReservationRuleOptions) => {
		if (!cancellationEvaluator)
			reservationsInfo.forEach(ri => ri.cancellationError = unauthorizedMessage)
		else {
			cancellationEvaluator.bulkEvaluate({ reservationsInfo: reservationsInfo, allReservations, userId: user?.id })
			reservationsInfo.forEach(ri => ri.cancellationError = ri.violation)
		}
	}, [cancellationEvaluator, user])

	const period = role?.periodRequiredToEvaluateRules

	const calcRulePeriodStart = useMemo(() =>
		period ?
			dateRangeParser(period.startOffset) :
			(date: Date) => date,
		[period])


	const calcRulePeriodEnd = useMemo(() =>
		period ?
			dateRangeParser(period.endOffset) :
			(date: Date) => new Date(new Date(date.setDate(date.getDate() + 2)).getTime() - 1),
		[period])

	const requiresPlayerSelection = role?.requiresPlayerSelection || false
	const canEnterDescription = role?.canEnterDescription || false
	const mustEnterDescription = role?.mustEnterDescription || false
	
	return { canReserve, canCancel, calcRulePeriodStart, calcRulePeriodEnd, requiresPlayerSelection, canEnterDescription, mustEnterDescription, bulkCheckCanReserve, bulkCheckCanCancel }
}

export default useReservationRules