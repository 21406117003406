import { useCallback } from "react"
import { useHistory } from "react-router-dom"
import { ReservationInfo } from "../../api"
import { useAuth, useShowMessage, useReservations, useCourts, useResInfoSelection } from "../../context"
import { ResponsiveReservationView } from "./variants"
import { useShowReservationDialog } from "../ReservationDialog"
import { useShowMultiResDialog } from "../MultiReservationDialog"
import { getSelectionSpan } from "../../tools/SelectionHelper"

type ReservationViewProps = {
	today: Date
}

function ReservationView({ today }: ReservationViewProps) {
	const courts = useCourts()

	const { cancelReservation, saveReservation, reservations, fetch, multiReservation } = useReservations()

	const { toggleSelection, multiSelectEnabled, selected, clearSelection, bulkSelect } = useResInfoSelection()

	const { user } = useAuth()

	const history = useHistory()

	const showMessage = useShowMessage()

	const showResDialog = useShowReservationDialog()
	const showMultiResDialog = useShowMultiResDialog()

	const onInfoClick = useCallback(
		(info: ReservationInfo) => {

			if (!info.reservation) {
				if (!user) {
					history.push("/login")
					showMessage({
						message: "Bitte meld dich an um reservieren zu können",
						severity: "info",
						duration: 3000
					})
					return
				}
				if (info.reservationError) {
					showMessage({
						message: info.reservationError,
						severity: "warning",
						duration: 5000
					})
					return
				}
			}
			if (!user) return

			if (multiSelectEnabled) {
				if (selected.length === 1) {
					bulkSelect(getSelectionSpan(selected[0], info, courts), true)
				}
				else
					toggleSelection(info)
				return
			}

			showResDialog({
				cancelReservation,
				saveReservation,
				reservationInfo: info
			})
		}, [multiSelectEnabled, user, showResDialog, history, cancelReservation, saveReservation, showMessage, toggleSelection, courts, selected, bulkSelect])

	const multiResClick = useCallback(
		(cancel: boolean) => () => {
			if (selected.length <= 0)
				return

			showMultiResDialog({ selected, cancel })
				.then(diaRes => {
					return multiReservation(selected, diaRes.description, cancel)
				})
				.then((rr) => { if (rr) clearSelection() })
				.catch(() => { /*ignore cancel*/ })
		},
		[selected, clearSelection, showMultiResDialog, multiReservation]
	)

	return (<ResponsiveReservationView
		reservations={reservations}
		fetch={fetch}
		courts={courts}
		today={today}
		onInfoClick={onInfoClick}
		user={user}
		multiResClick={multiResClick(false)}
		multiCancelClick={multiResClick(true)}
		clearSelClick={() => clearSelection()}
	/>)
}

ReservationView.defaultProps = {
	today: new Date()
}

export default ReservationView